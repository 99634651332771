import styled, { css } from 'styled-components';

export const Menu = styled.div`
  @media only screen and (max-width: 450px) {
    display: none;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    display: none;
  }
  @media only screen and (min-width: 1024px) {
    padding-right: 20px;
  }
  @media only screen and (min-width: 1361px) {
    padding-right: 50px;
  }
`;

interface IClassHeader {
  minhaClasse: string;
}

export const ClassHeader = styled.div<IClassHeader>`
  align-items: center;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem;
  color: #354649;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  transition: ease-out, 0.5s;
  width: 100%;
  z-index: 102;
  @media only screen and (min-width: 1024px) {
    position: fixed;
  }
  ${props =>
    props.minhaClasse === 'header-banner' &&
    css`
      background: none;
      box-shadow: none;
      @media only screen and (min-width: 1024px) {
        position: absolute;
      }
    `};
`;

export const Logo = styled.div`
  @media only screen and (max-width: 1023px) {
    width: 100%;
    display: grid;
    justify-items: center;
  }
  @media only screen and (min-width: 1024px) {
    padding-left: 20px;
  }
`;

interface IScreen {
  md?: boolean;
}

export const LogoCatalogo = styled.img<IScreen>`
  @media only screen and (max-width: 450px) {
    width: 90px;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    width: 120px;
  }
  @media only screen and (min-width: 1024px) {
    width: 200px;
    display: none;
  }

  ${props =>
    props.md &&
    css`
      display: none;
      @media only screen and (max-width: 450px) {
        width: 90px;
      }
      @media only screen and (max-width: 1023px) and (min-width: 451px) {
        width: 120px;
      }
      @media only screen and (min-width: 1024px) {
        width: 180px;
        display: block;
      }
    `};
`;

export const HeaderContent = styled.div`
  align-items: center;
  color: #354649;
  display: flex;
  font-weight: 400;
  justify-content: space-between;
  width: 100%;
  @media only screen and (min-width: 1024px) {
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5rem 1rem;
    z-index: 102;
    transition: ease-in, 0.5s;
  }
`;

export const Login = styled.div<IScreen>`
  float: right;
  position: absolute;
  right: 10px;
  top: 10px;
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;

export const IconLogin = styled.img`
  @media only screen and (max-width: 450px) {
    width: 35px;
    padding-right: 5px;
    float: right;
    padding-top: 10px;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    width: 35px;
    padding-top: 10px;
    padding-right: 15px;
    float: right;
  }
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`;

export const MenuItem = styled.span`
  padding-right: 100px;
`;

export const BtnPanelLogin = styled.button`
  background-color: #354649;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-family: 'Poppins', sans-serif !important;
  font-size: 1.1em;
  font-weight: 300;
  outline: none;
  padding: 8px 25px;
  text-align: center;
  text-decoration: none;
  transition: all 0.4s ease-in;

  &:hover {
    opacity: 0.8;
  }
`;

export const Links = styled.a`
  &:link {
    color: #354649;
    text-decoration: none !important;
  }

  &:visited {
    color: #354649 !important;
    text-decoration: none !important;
  }

  &:hover {
    color: #354649 !important;
    text-decoration: none !important;
  }

  &:active {
    color: #354649 !important;
    text-decoration: none !important;
  }
`;

export const BlackText = styled.span`
  font-weight: 500;
`;
