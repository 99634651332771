import React, { createContext, useCallback } from 'react';

import useToast from 'hooks/useToast';
import httpClient from 'services/httpClient';
import ICreatePaymentPostRequest from 'services/httpClient/requests/pagamento/ICreatePaymentPost';
import ICreatePaymentPostResponse from 'services/httpClient/responses/pagamento/ICreatePaymentPost';
import formatter from 'utils/formatter';

import { ICreatePaymentDTO, IUsePaymentContext } from './index';

export const PaymentContext = createContext<IUsePaymentContext>(
  {} as IUsePaymentContext,
);

const UsePaymentProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();

  const createPayment = useCallback(
    async ({ data, functions }: ICreatePaymentDTO) => {
      try {
        const payload: ICreatePaymentPostRequest = {
          document: formatter.removeMask({ value: data.document }),
          name: {
            company: data.companyName || data.name,
            user: data.name,
          },
          password: data.password,
          phone: formatter.removeMask({ value: data.phone }),
          email: data.email,
        };
        await httpClient.post<ICreatePaymentPostResponse>('accounts', payload);
        functions.stopLoading();
        functions.resetForm();
        functions.openModalSuccess();
      } catch (err) {
        functions.stopLoading();

        if (err.isAxiosError) {
          if (err.response.status === 422) {
            addToast({
              title: 'Este e-mail já está em uso',
              type: 'info',
            });
            return;
          }

          if (
            err.response.status === 400 &&
            err.response.data.error.code === 'alfred'
          ) {
            addToast({
              title: err.response.data.error.message,
              type: 'error',
            });
            return;
          }
        }

        addToast({
          title: 'Não foi possível efetuar o cadastro',
          type: 'error',
        });
      }
    },
    [addToast],
  );

  const handlesExported = {
    createPayment,
  };

  const statesExported = {};

  return (
    <PaymentContext.Provider value={{ ...handlesExported, ...statesExported }}>
      {children}
    </PaymentContext.Provider>
  );
};

export default UsePaymentProvider;
