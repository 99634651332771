import React, { useCallback, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { FiAlertCircle } from 'react-icons/fi';
import { PulseLoader } from 'react-spinners';

import DisplayComponent from 'components/DisplayComponent';
import { Field, FieldProps, Form, Formik } from 'formik';
import useContact from 'hooks/useContact';
import * as Yup from 'yup';

import {
  BtnSend,
  Contact,
  ContentContact,
  FormContent,
  FormTitle,
  Input,
  InputContainer,
  SendContact,
  Subtitle,
  Title,
  TooltipContainer,
  TooltipTitle,
} from './styles';

interface IContactFormData {
  companyName: string;
  email: string;
  name: string;
  observation: string;
  office: string;
  phone: string;
}

const Contato: React.FC = () => {
  const { send: handleSendContact } = useContact();

  const history = useHistory();

  const [isLoadingFormContact, setIsLoadingFormContact] = useState(false);

  const validationContactForm = Yup.object().shape({
    name: Yup.string().required('Informe um nome e sobrenome'),
    companyName: Yup.string().required('Informe o nome da empresa'),
    email: Yup.string()
      .required('Informe um e-mail')
      .email('Informe um e-mail válido'),
    phone: Yup.string()
      .required('Informe um telefone')
      .matches(/\(\d{2}\)\s\d{4,5}-\d{4}/, 'Informe um telefone válido'),
  });

  const maskPhone = (value: string, defaultValue = '') => {
    if (!value) {
      return defaultValue;
    }

    const phoneClean = value.replace(/[^0-9]/g, '');

    let phoneFormatted = '';
    if (phoneClean.length < 11) {
      phoneFormatted = phoneClean
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{4})(\d)/, '$1-$2');
    } else {
      phoneFormatted = phoneClean
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2');
    }

    return phoneFormatted.substr(0, 15);
  };

  const handleCreateContact = useCallback(
    async (data: IContactFormData) => {
      setIsLoadingFormContact(true);

      handleSendContact({
        data: {
          companyName: data.companyName,
          email: data.email,
          name: data.name,
          phone: data.phone,
          observation: data.observation,
          office: data.office,
        },
        functions: {
          onSuccess: () => {
            history.push('/contato-enviado');
          },
          stopLoading: () => {
            setIsLoadingFormContact(false);
          },
        },
      });
    },
    [handleSendContact, history],
  );

  const customInputComponent = (props: any) => (
    <textarea
      {...props}
      style={{ height: '75px', width: '98%', resize: 'none' }}
    />
  );

  const Basic = () => (
    <>
      <Formik
        initialValues={{
          name: '',
          companyName: '',
          office: '',
          email: '',
          phone: '',
          observation: '',
        }}
        onSubmit={handleCreateContact}
        validationSchema={validationContactForm}
      >
        {({ setFieldValue }) => (
          <Form>
            <FormContent>
              <FormTitle>Contato</FormTitle>
            </FormContent>

            <FormContent>
              <Field id="name" name="name">
                {({ field, meta }: FieldProps) => (
                  <InputContainer>
                    <Input
                      className="formStyle"
                      placeholder="Nome e sobrenome"
                      {...field}
                    />

                    {meta.touched && meta.error && (
                      <TooltipContainer>
                        <FiAlertCircle color="#fff1d1fa" size={18} />
                        <TooltipTitle>{meta.error}</TooltipTitle>
                      </TooltipContainer>
                    )}
                  </InputContainer>
                )}
              </Field>
            </FormContent>

            <FormContent>
              <Field id="companyName" name="companyName">
                {({ field, meta }: FieldProps) => (
                  <InputContainer>
                    <Input
                      className="formStyle"
                      placeholder="Empresa"
                      {...field}
                    />

                    {meta.touched && meta.error && (
                      <TooltipContainer>
                        <FiAlertCircle color="#fff1d1fa" size={18} />
                        <TooltipTitle>{meta.error}</TooltipTitle>
                      </TooltipContainer>
                    )}
                  </InputContainer>
                )}
              </Field>
            </FormContent>

            <FormContent>
              <Field
                className="formStyle"
                id="office"
                name="office"
                placeholder="Cargo"
              />
            </FormContent>

            <FormContent>
              <Field id="email" name="email">
                {({ field, meta }: FieldProps) => (
                  <InputContainer>
                    <Input
                      className="formStyle"
                      placeholder="E-mail"
                      type="email"
                      {...field}
                    />

                    {meta.touched && meta.error && (
                      <TooltipContainer>
                        <FiAlertCircle color="#fff1d1fa" size={18} />
                        <TooltipTitle>{meta.error}</TooltipTitle>
                      </TooltipContainer>
                    )}
                  </InputContainer>
                )}
              </Field>
            </FormContent>

            <FormContent>
              <Field id="phone" name="phone">
                {({ field, meta }: FieldProps) => (
                  <InputContainer>
                    <Input
                      className="formStyle"
                      placeholder="Telefone"
                      {...field}
                      onChange={e => {
                        setFieldValue('phone', maskPhone(e.target.value));
                      }}
                    />

                    {meta.touched && meta.error && (
                      <TooltipContainer>
                        <FiAlertCircle color="#fff1d1fa" size={18} />
                        <TooltipTitle>{meta.error}</TooltipTitle>
                      </TooltipContainer>
                    )}
                  </InputContainer>
                )}
              </Field>
            </FormContent>

            <FormContent>
              <Field
                as={customInputComponent}
                className="formStyle"
                id="observation"
                name="observation"
                placeholder="Observações"
              />
            </FormContent>

            <BtnSend type="submit">
              <DisplayComponent when={isLoadingFormContact}>
                <PulseLoader color="#fff" size={7} />
              </DisplayComponent>

              <DisplayComponent when={!isLoadingFormContact}>
                Solicitar contato
              </DisplayComponent>
            </BtnSend>
          </Form>
        )}
      </Formik>
    </>
  );

  return (
    <Contact id="contato">
      <SendContact md>
        <Basic />
      </SendContact>

      <ContentContact>
        <Title>
          O Meu Catálogo tem tudo o que você precisa para apresentar seus
          produtos e aumentar suas vendas
        </Title>
        <Subtitle>Entre em contato, faremos todo o resto por você!</Subtitle>
      </ContentContact>

      <SendContact>
        <Basic />
      </SendContact>
    </Contact>
  );
};

export default Contato;
