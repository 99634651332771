import React, { useEffect, useState } from 'react';

// conteudo

// components
import Banner from './components/Banner';
import Contato from './components/Contato';
import Footer from './components/Footer';
import Funcionalidades from './components/Funcionalidades';
import Sobre from './components/Sobre';
import Data from './Data/textos';

const App: React.FC = () => {
  const [headerStyle, setHeaderStyle] = useState('header-banner');
  useEffect(() => {
    document.body.style.overflow = 'auto';
    window.addEventListener('scroll', () => {
      const { scrollY } = window;
      if (scrollY >= 150) {
        setHeaderStyle('header-content');
      } else {
        setHeaderStyle('header-banner');
      }
    });
  });

  return (
    <>
      <Banner classe={headerStyle} />
      <Sobre conteudo={Data[0].conteudo} titulo={Data[0].titulo} />
      <Funcionalidades />
      <Contato />
      <Footer classe="footerPrincipal" />
    </>
  );
};

export default App;
