import { isValid } from 'date-fns';

import formatter from './formatter';

function cnpj(value: string) {
  const cnpjClean = formatter.removeMask({ value });

  if (cnpjClean === '') {
    return false;
  }

  if (cnpjClean.length !== 14) {
    return false;
  }

  if (
    cnpjClean === '00000000000000' ||
    cnpjClean === '11111111111111' ||
    cnpjClean === '22222222222222' ||
    cnpjClean === '33333333333333' ||
    cnpjClean === '44444444444444' ||
    cnpjClean === '55555555555555' ||
    cnpjClean === '66666666666666' ||
    cnpjClean === '77777777777777' ||
    cnpjClean === '88888888888888' ||
    cnpjClean === '99999999999999'
  ) {
    return false;
  }

  let length = cnpjClean.length - 2;
  let numbers = cnpjClean.substring(0, length);
  const digits = cnpjClean.substring(length);
  let soma = 0;
  let pos = length - 7;
  let i;

  for (i = length; i >= 1; i -= 1) {
    // eslint-disable-next-line no-plusplus
    soma += Number(numbers.charAt(length - i)) * pos--;

    if (pos < 2) {
      pos = 9;
    }
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado !== Number(digits.charAt(0))) {
    return false;
  }

  length += 1;
  numbers = cnpjClean.substring(0, length);
  soma = 0;
  pos = length - 7;

  for (i = length; i >= 1; i -= 1) {
    // eslint-disable-next-line no-plusplus
    soma += Number(numbers.charAt(length - i)) * pos--;

    if (pos < 2) {
      pos = 9;
    }
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);

  if (resultado !== Number(digits.charAt(1))) {
    return false;
  }

  return true;
}

function cpf(value: string) {
  let sum = 0;
  let rest;

  const valueClean = formatter.removeMask({ value });

  if (valueClean.length !== 11) {
    return false;
  }

  if (
    valueClean === '00000000000' ||
    valueClean === '11111111111' ||
    valueClean === '22222222222' ||
    valueClean === '33333333333' ||
    valueClean === '44444444444' ||
    valueClean === '55555555555' ||
    valueClean === '66666666666' ||
    valueClean === '77777777777' ||
    valueClean === '88888888888' ||
    valueClean === '99999999999'
  ) {
    return false;
  }

  for (let i = 1; i <= 9; ) {
    sum += parseInt(valueClean.substring(i - 1, i), 10) * (11 - i);

    i += 1;
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(valueClean.substring(9, 10), 10)) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; ) {
    sum += parseInt(valueClean.substring(i - 1, i), 10) * (12 - i);

    i += 1;
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(valueClean.substring(10, 11), 10)) {
    return false;
  }

  return true;
}

function date(value: Date): boolean {
  const isDateValid = isValid(value);

  return isDateValid;
}

function phone(value: string) {
  const valueLength = value.length;

  if (valueLength < 14) {
    return false;
  }

  return true;
}

export default { cnpj, cpf, date, phone };
