import React from 'react';

import { BackdropContainer } from './styles';

interface IBackdropProps {
  onClick?(): void;
  transform?: string;
  zIndex: string;
}

const Backdrop: React.FC<IBackdropProps> = ({ onClick, transform, zIndex }) => {
  return (
    <BackdropContainer
      onClick={onClick}
      transform={transform}
      zIndex={zIndex}
    />
  );
};

export default Backdrop;
