import React, { InputHTMLAttributes, useEffect, useRef } from 'react';

import { useField } from '@unform/core';

import { Container, Input, Span } from './styles';

interface IComponentInputCheckboxProps
  extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  name: string;
}

const ComponentInputCheckbox: React.FC<IComponentInputCheckboxProps> = ({
  label,
  name,
  ...props
}) => {
  const { defaultValue = false, error, fieldName, registerField } = useField(
    name,
  );

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);

  return (
    <Container isErrored={!!error}>
      {label}

      <Input
        {...props}
        defaultChecked={!!defaultValue}
        name={fieldName}
        ref={inputRef}
        type="checkbox"
      />
      <Span className="checkmark" isErrored={!!error} />
    </Container>
  );
};

export default ComponentInputCheckbox;
