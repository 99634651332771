import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { useHistory } from 'react-router-dom';

import Lottie from 'react-lottie';

import success from 'assets/success.json';
import Backdrop from 'components/Backdrop';
import DisplayComponent from 'components/DisplayComponent';

import {
  Actions,
  Br,
  GoPanel,
  ModalSuccessAction,
  ModalSuccessContainer,
  ModalSuccessContent,
  ModalSuccessMessage,
  ModalSuccessTitle,
} from './styles';

export interface IComponentSuccessRefProps {
  open(): void;
}

const ComponentSuccess: React.ForwardRefRenderFunction<IComponentSuccessRefProps> = (
  _,
  ref,
) => {
  const history = useHistory();

  const [isOpened, setIsOpened] = useState<boolean>(false);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: success,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  function goHome() {
    history.push('/');
    closeModal();
  }

  const openModal = useCallback(() => {
    setIsOpened(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpened(false);
  }, []);

  useImperativeHandle(ref, () => ({
    open: openModal,
  }));

  useEffect(() => {
    if (isOpened) {
      document.body.style.overflow = 'hidden';
    }
  }, [isOpened]);

  return (
    <DisplayComponent when={isOpened}>
      <ModalSuccessContainer>
        <Backdrop onClick={goHome} zIndex="12" />
        <ModalSuccessContent>
          <Lottie
            height={80}
            isPaused={false}
            isStopped={false}
            options={defaultOptions}
            width={80}
          />

          <ModalSuccessTitle>Parabéns!</ModalSuccessTitle>

          <ModalSuccessMessage>
            Seu cadastro foi concluído com sucesso.
            <Br />
            <Br />
            Você já pode acessar o painel e começar a cadastrar seus produtos.
          </ModalSuccessMessage>

          <Actions>
            <GoPanel href={process.env.REACT_APP_URL_ADMIN} target="_blank">
              Ir para o painel
            </GoPanel>
            <ModalSuccessAction onClick={goHome}>
              Voltar ao início
            </ModalSuccessAction>
          </Actions>
        </ModalSuccessContent>
      </ModalSuccessContainer>
    </DisplayComponent>
  );
};

export default forwardRef(ComponentSuccess);
