import styled from 'styled-components';

import colors from 'styles/colors';

export const ModalSuccessContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 90%;
  z-index: 99999;

  @media (min-width: 375px) {
    padding: 1.15rem;
  }

  @media (min-width: 425px) {
    padding: 1.25rem;
  }

  @media (min-width: 768px) {
    padding: 2.4rem;
    width: 100%;
  }
`;

export const ModalSuccessContent = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.borderColor};
  border-radius: 8px;
  max-width: 350px;
  padding: 2.4rem;
  width: 100%;
  z-index: 15;
`;

export const ModalSuccessTitle = styled.h4`
  color: ${colors.primary};
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 24px;
  text-align: center;
`;

export const ModalSuccessMessage = styled.p`
  color: ${colors.secondaryText};
  font-size: 14px;
  margin-bottom: 16px;
  margin-top: 0;
  text-align: center;
`;

export const Br = styled.br``;

export const Actions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 32px;
`;

export const GoPanel = styled.a`
  align-items: center;
  background-color: ${colors.primary};
  border: 0;
  border-radius: 8px;
  color: ${colors.white} !important;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 35px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  width: 150px;
  &:hover {
    color: ${colors.white};
    opacity: 0.8;
  }
`;

export const ModalSuccessAction = styled.button`
  align-items: center;
  background-color: ${colors.secondary};
  border: 0;
  border-radius: 8px;
  color: ${colors.white};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 35px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 150px;
  &:hover {
    color: ${colors.white};
    opacity: 0.8;
  }
`;
