import React from 'react';

import { useHistory } from 'react-router-dom';

import EPlans from 'constants/plans';

import {
  BottomBackground,
  CardButton,
  CardLi,
  CardPt1,
  CardPt2,
  CardPt3,
  CardSubtitle,
  CardTitle,
  ContentFunc,
  DivFunc,
  FunctionImage,
  Functions,
  FunctionTitle,
  IsComming,
  Li,
  ListItems,
  Plan,
  Plans,
  PlansList,
  Price,
  SubtitleListItems,
  Title,
  TotalContent,
  Ul,
} from './styles';

const Funcionalidades: React.FC = () => {
  const history = useHistory();

  const goCreateAccount = (
    planAmount: string,
    planType: EPlans,
    planId: number,
    paymentType?: string,
  ) => {
    history.push('/assinar-pacote', {
      amount: planAmount,
      plan: planType,
      paymentType,
      planId,
    });
  };

  return (
    <>
      <Functions id="recursos">
        <Title positionAbsolute>Conheça as funcionalidades do aplicativo</Title>
        <TotalContent md>
          <DivFunc>
            <ContentFunc>
              <FunctionTitle>Catálogo</FunctionTitle>
              <Ul>
                <Li>Cadastro de produtos com fotos e descrição</Li>
                <Li>Publicação automática</Li>
                <Li>Permite gerar cotações facilmente</Li>
                <Li>Painel administrativo para gestão completa</Li>
                <Li>Para computador, tablet e celular</Li>
              </Ul>
            </ContentFunc>
            <ContentFunc>
              <FunctionTitle>Cotação</FunctionTitle>
              <Ul>
                <Li>
                  Permite ao vendedor cotar os produtos do catálogo para o
                  cliente
                </Li>
              </Ul>
            </ContentFunc>
          </DivFunc>
          <FunctionImage src="https://cdn-rac.azureedge.net/assets/site-catalogo/img-func.png" />
        </TotalContent>
        <TotalContent>
          <FunctionImage src="https://cdn-rac.azureedge.net/assets/site-catalogo/img-func.png" />
          <ContentFunc>
            <FunctionTitle>Catálogo</FunctionTitle>
            <Ul>
              <Li>Cadastro de produtos com fotos e descrição</Li>
              <Li>Publicação automática</Li>
              <Li>Permite gerar cotações facilmente</Li>
              <Li>Painel administrativo para gestão completa</Li>
              <Li>Para computador, tablet e celular</Li>
            </Ul>
          </ContentFunc>
          <ContentFunc>
            <FunctionTitle>Cotação</FunctionTitle>
            <Ul>
              <Li>
                Permite ao vendedor cotar os produtos do catálogo para o cliente
              </Li>
            </Ul>
          </ContentFunc>
        </TotalContent>
      </Functions>

      <Plans id="planos">
        <Title>Escolha o melhor plano para o seu negócio</Title>

        <PlansList>
          <Plan>
            <CardPt1>
              <CardTitle>Free</CardTitle>
              <CardSubtitle>Plano gratuito</CardSubtitle>
            </CardPt1>
            <CardPt2>
              <Price>R$ 0,00</Price>
              <CardSubtitle>Mensal</CardSubtitle>
              <CardButton
                onClick={() => {
                  goCreateAccount('R$ 0,00', EPlans.FREE, 1);
                }}
              >
                CRIAR CONTA GRÁTIS
              </CardButton>
            </CardPt2>
            <CardPt3>
              <Ul>
                <CardLi>
                  <ListItems>Permite incluir até 200 produtos</ListItems>
                </CardLi>
                <CardLi>
                  <ListItems>
                    Painel administrativo para gestão completa
                  </ListItems>
                </CardLi>
                <CardLi>
                  <ListItems>
                    Cadastro de produtos com foto e descrição
                  </ListItems>
                </CardLi>
                <CardLi>
                  <ListItems>Publicação automática</ListItems>
                </CardLi>
                <CardLi>
                  <ListItems>Para computador, tablet e celular</ListItems>
                </CardLi>
              </Ul>
            </CardPt3>
          </Plan>
          <Plan>
            {/* <FlexPopular>Popular</FlexPopular> */}
            <CardPt1 commingSoon>
              <CardTitle>Flex</CardTitle>
              <CardSubtitle>O ideal para o seu negócio</CardSubtitle>
            </CardPt1>
            <CardPt2>
              <IsComming>Em breve</IsComming>
            </CardPt2>
            <CardPt3 commingSoon>
              <Ul>
                <CardLi>
                  <ListItems>Tabela de preços</ListItems>
                </CardLi>
                <CardLi>
                  <ListItems>Permite criar cotações</ListItems>
                </CardLi>
                <CardLi>
                  <ListItems>Uso ilimitado de imagens</ListItems>
                </CardLi>
                <CardLi>
                  <ListItems>
                    Painel administrativo para gestão completa
                  </ListItems>
                </CardLi>
                <CardLi>
                  <ListItems>
                    Cadastro de produtos com foto e descrição
                  </ListItems>
                </CardLi>
                <CardLi>
                  <ListItems>Publicação automática</ListItems>
                </CardLi>
                <CardLi>
                  <ListItems>Para computador, tablet e celular</ListItems>
                </CardLi>
              </Ul>
            </CardPt3>
          </Plan>

          <Plan>
            <CardPt1 commingSoon>
              <CardTitle>Premium</CardTitle>
              <CardSubtitle>Para distribuidoras de medicamentos</CardSubtitle>
            </CardPt1>
            <CardPt2>
              <IsComming>Em breve</IsComming>
            </CardPt2>
            <CardPt3 commingSoon>
              <Ul>
                <CardLi style={{ paddingBottom: '15px' }}>
                  <ListItems>Fornecimento de imagens</ListItems>
                  <SubtitleListItems>
                    Para distribuidoras de medicamentos
                  </SubtitleListItems>
                </CardLi>
                <CardLi>
                  <ListItems>Tabela de preços</ListItems>
                </CardLi>
                <CardLi>
                  <ListItems>Uso ilimitado de imagens</ListItems>
                </CardLi>
                <CardLi>
                  <ListItems>Permite criar cotações</ListItems>
                </CardLi>
                <CardLi>
                  <ListItems>
                    Painel administrativo para gestão completa
                  </ListItems>
                </CardLi>
                <CardLi>
                  <ListItems>
                    Cadastro de produtos com foto e descrição
                  </ListItems>
                </CardLi>
                <CardLi>
                  <ListItems>Publicação automática</ListItems>
                </CardLi>
                <CardLi>
                  <ListItems>Para computador, tablet e celular</ListItems>
                </CardLi>
              </Ul>
            </CardPt3>
          </Plan>
        </PlansList>
      </Plans>
      <BottomBackground />
    </>
  );
};

export default Funcionalidades;
