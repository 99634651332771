import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';

interface IClassFooter {
  classe: string;
}

export const FooterContent = styled.div<IClassFooter>`
  @media only screen and (max-width: 450px) {
    display: grid;
    justify-items: center;
    text-align: center;
  }
  @media only screen and (min-width: 451px) {
    display: flex;
  }
  ${props =>
    props.classe === 'footerPrincipal' &&
    css`
      background-image: url('https://cdn-rac.azureedge.net/assets/site-catalogo/bkground-top.svg');
      background-position-x: center;
      background-position-y: top;
      background-repeat: no-repeat;
      @media only screen and (max-width: 450px) {
        padding-top: 200px;
      }
      @media only screen and (max-width: 1023px) and (min-width: 451px) {
        padding-top: 200px;
      }
      @media only screen and (min-width: 1024px) {
        padding-top: 300px;
      }
    `};
`;

export const ImgRedes = styled.div`
  padding-top: 10px;
`;

export const RedesSociais = styled.div`
  @media only screen and (max-width: 450px) {
    padding: 10px;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    padding: 50px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 50px;
  }
`;

export const LogoRac = styled.img`
  width: 75px;
`;

export const Description = styled.p`
  color: #354649;
  font-size: 15px;
  font-weight: 300;
  line-height: 15px;
  margin-bottom: 0;
  margin-top: 0;
  padding-top: 10px;
`;

export const LogoRedes = styled.img`
  padding-right: 5px;
  width: 25px;
`;

export const LogoDescription = styled.div`
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    padding: 50px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 50px;
  }
`;

export const Links = styled.a`
  transition: all 0.3s ease-in;
  &:link {
    color: #354649;
    text-decoration: none !important;
  }

  &:visited {
    color: #354649 !important;
    text-decoration: none !important;
  }

  &:hover {
    color: #354649 !important;
    opacity: 0.75;
    text-decoration: none !important;
  }

  &:active {
    color: #354649 !important;
    text-decoration: none !important;
  }
`;

export const BlackText = styled.span``;

export const SpanButtons = styled.span`
  font-size: 16px;
  font-weight: 500;
`;

export const PagesLink = styled(Link)`
  transition: all 0.3s ease-in;
  & + a {
    margin-left: 1rem;
  }
  &:hover {
    opacity: 0.75;
  }
`;
