import React, {
  ChangeEvent,
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react';

import { FiAlertCircle } from 'react-icons/fi';

import { useField } from '@unform/core';
import colors from 'styles/colors';
import formatter from 'utils/formatter';

import { Container, Error, Input } from './styles';

interface IComponentInputCardNumberProps
  extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const ComponentInputCardNumber: React.FC<IComponentInputCardNumberProps> = ({
  name,
  ...props
}) => {
  const { defaultValue, error, fieldName, registerField } = useField(name);

  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputBlur = () => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;

    const textFormatted = formatter.cardNumber({ value: text });

    if (inputRef.current) {
      inputRef.current.value = textFormatted;
    }
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
      <Input
        defaultValue={defaultValue}
        id={fieldName}
        maxLength={19}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        ref={inputRef}
        type="text"
        {...props}
      />

      {!!error && (
        <Error title={error}>
          <FiAlertCircle color={colors.error} size={18} />
        </Error>
      )}
    </Container>
  );
};

export default ComponentInputCardNumber;
