import styled from 'styled-components';

export const TermosContent = styled.div`
  display: grid;
  justify-items: center;
  padding-bottom: 3rem;
  padding-top: 8rem;
`;

export const Title = styled.h1`
  color: #37aa9c;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
`;

export const Content = styled.div`
  width: 80%;
`;

export const Subtitle = styled.h2`
  color: #22536c;
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 50px;
`;

export const P = styled.p`
  color: #555;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.25rem;
  margin: 0 1rem 2.5rem;
  text-align: justify;
`;

interface IBoldProps {
  underline?: boolean;
}

export const Bold = styled.b<IBoldProps>`
  font-weight: 500;
  text-decoration: ${props => props.underline && 'underline'};
`;

export const Br = styled.br``;

export const DivideFooter = styled.hr`
  background-image: linear-gradient(to right, transparent, #ccc, transparent);
  border: 0;
  height: 2px;
`;
