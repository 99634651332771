import styled, { css } from 'styled-components';

import colors from 'styles/colors';

export const Functions = styled.div`
  background-color: #fff;
  background-image: url('https://cdn-rac.azureedge.net/assets/site-catalogo/bkground-top.svg');
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  @media only screen and (max-width: 450px) {
    padding-top: 150px;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    padding-top: 230px;
  }
  @media only screen and (min-width: 1024px) {
    padding-top: 330px;
    height: 95vh;
  }
`;

interface ITitle {
  positionAbsolute?: boolean;
}

export const Title = styled.p<ITitle>`
  color: #37aa9c;
  margin-bottom: 100px;
  text-align: center;
  width: 100%;

  @media only screen and (max-width: 450px) {
    font-size: 30px;
    padding-bottom: 45px;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    font-size: 40px;
    padding-bottom: 65px;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 40px;
    ${props =>
      props.positionAbsolute &&
      css`
        position: absolute;
      `}
  }
`;

interface ITotalContent {
  md?: boolean;
}

export const TotalContent = styled.div<ITotalContent>`
  align-items: center;
  height: 100%;
  justify-content: space-evenly;
  @media only screen and (max-width: 450px) {
    display: grid;
    padding-bottom: 45px;
    padding-left: 22px;
    padding-right: 22px;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    display: grid;
  }
  @media only screen and (min-width: 1024px) {
    display: flex;
  }
  ${props =>
    !props.md &&
    css`
      display: none !important;
    `}
`;

export const ContentFunc = styled.div`
  border-left: 5px solid #e3d985;
  cursor: pointer;
  padding-left: 15px;
`;

export const DivFunc = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FunctionTitle = styled.div`
  color: #e3d985;
  font-size: 25px;
  font-weight: 300;
  margin-bottom: 0;
`;

export const FunctionImage = styled.img`
  @media only screen and (max-width: 450px) {
    width: 250px;
    justify-self: center;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    width: 350px;
    justify-self: center;
  }
  @media only screen and (min-width: 1024px) {
    width: 500px;
  }
`;

export const Ul = styled.ul`
  padding: 15px;
  &::before {
    display: inline-block;
    font-weight: bold;
    margin-left: -1em;
    width: 1em;
  }
`;

export const Li = styled.li`
  &::before {
    display: inline-block;
    font-weight: bold;
    margin-left: -1em;
    width: 1em;
  }
`;

export const BottomBackground = styled.div`
  background-color: #fff;
  background-image: url('https://cdn-rac.azureedge.net/assets/site-catalogo/bkground-bottom.svg');
  background-position-x: center;
  background-position-y: center;
  background-repeat: no-repeat;
  height: 150px;
`;

export const PlansList = styled.div`
  @media only screen and (max-width: 1023px) {
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    padding-left: 15px;
    width: calc(100% - 30px);
  }

  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-evenly;
  }
`;

interface IPlan {
  greenBackgroundColor?: boolean;
}

export const Plans = styled.div`
  @media only screen and (min-width: 1024px) {
    margin-bottom: 150px;
  }
`;

export const Plan = styled.div<IPlan>`
  border: 2px solid #e4db8d;
  border-radius: 20px;
  display: grid;
  padding: 35px;
  position: relative;

  ${props =>
    props.greenBackgroundColor &&
    css`
      background: #e4db8d;
    `}

  @media only screen and (max-width: 450px) {
    min-width: 220px;
    width: 220px;
    height: 590px;
    grid-template-rows: 1fr 1fr 390px;
    margin-right: 15px;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    min-width: 250px;
    width: 250px;
    height: 550px;
    grid-template-rows: 1fr 1fr 360px;
    margin-right: 15px;
  }
  @media only screen and (min-width: 1024px) {
    width: 280px;
    height: 550px;
    grid-template-rows: 1fr 1fr 360px;
  }
`;

export const CardTitle = styled.p`
  color: #354649;
  font-size: 22px;
  font-weight: 600;
  margin: 0;
`;

export const CardSubtitle = styled.p`
  color: #354649;
  font-size: 13px;
  font-weight: 400;
  margin: 0;
`;

export const SubtitleListItems = styled.span`
  color: #37aa9c;
  font-size: 10px;
  font-weight: 400;
  margin: 0;
  padding-left: 5px;
  position: absolute;
  @media only screen and (max-width: 450px) {
    top: 20px;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    top: 20px;
  }
  @media only screen and (min-width: 1024px) {
    top: 20px;
  }
`;

export const Price = styled.p`
  color: #354649;
  font-size: 28px;
  font-weight: 600;
  margin: 0;
`;

export const CardButton = styled.button`
  background-color: #354649;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-family: 'Poppins', sans-serif !important;
  font-size: 1.1em;
  font-size: 16px;
  font-weight: 300;
  margin-top: 20px;
  outline: none;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  width: 100%;
`;

export const ListItems = styled.p`
  margin: 0;
  padding-bottom: 15px;
  padding-left: 5px;
`;

export const CardLi = styled.li`
  color: #354649;
  display: flex;
  position: relative;
  &::before {
    color: #354649;
  }
`;

export const FlexPopular = styled.button`
  background-color: #e3d985;
  border: 2px solid #37aa9c;
  border-radius: 10px;
  color: #37aa9c;
  font-size: 12px;
  font-weight: 600;
  height: 28px;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 86px;
`;

interface ICardPtProps {
  commingSoon?: boolean;
}

export const CardPt1 = styled.div<ICardPtProps>`
  margin-top: 0px;
  ${props =>
    props.commingSoon &&
    css`
      opacity: 0.6;
    `};
`;
export const CardPt2 = styled.div`
  margin-top: 0px;
`;

export const IsComming = styled.h4`
  color: ${colors.secondary};
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
`;

export const CardPt3 = styled.div<ICardPtProps>`
  margin-top: 0px;
  ${props =>
    props.commingSoon &&
    css`
      opacity: 0.6;
    `};
`;
