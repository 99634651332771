import 'normalize.css';
import './index.css';
import React from 'react';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import ReactDOM from 'react-dom';

import App from './App';
import NotFound from './components/NotFound';
import Politicas from './components/Politicas';
import Termos from './components/Termos';
import AppProvider from './hooks';
import CheckContact from './pages/CheckContact';
import Plan from './pages/Plan';

ReactDOM.render(
  <AppProvider>
    <BrowserRouter>
      <Switch>
        <Route component={App} exact path="/" />
        <Route component={Termos} path="/termos" />
        <Route component={Politicas} path="/politicas" />
        <Route component={NotFound} path="/404" />
        <Route component={Plan} exact path="/assinar-pacote" />
        <Route component={CheckContact} exact path="/contato-enviado" />
        <Redirect to="/404" />
      </Switch>
    </BrowserRouter>
  </AppProvider>,
  document.getElementById('root'),
);
