import React from 'react';

import Header from '../Header';
import {
  BlackText,
  Buttons,
  Content,
  DivBanner,
  DonwloadButton,
  ImgButton,
  ImgSreen,
  Left,
  Links,
  SpanButtons,
  Subtitle,
  TextButtons,
  Title,
} from './styles';

interface IBannerProps {
  classe: string;
}

const Banner: React.FC<IBannerProps> = ({ classe }) => {
  return (
    <DivBanner id="home">
      <Header classe={classe} />
      <Content>
        <Left>
          <Title>Uma nova forma de</Title>
          <Title>apresentar seus produtos</Title>
          <Subtitle md padding>
            Destaque seu produto numa aplicação moderna, ágil, personalizando do
            seu jeito com a melhor qualidade.
          </Subtitle>
          <Buttons md>
            <Links
              href="https://apps.apple.com/br/app/meu-cat%C3%A1logo/id1534367526"
              rel="noopener noreferrer"
              target="_blank"
            >
              <DonwloadButton>
                <ImgButton src="./Imagens/logo-apple-appstore.svg" />
                <TextButtons>
                  <SpanButtons>Disponível na </SpanButtons>
                  <SpanButtons>
                    <BlackText>AppStore</BlackText>
                  </SpanButtons>
                </TextButtons>
              </DonwloadButton>
            </Links>
            <Links
              href="https://play.google.com/store/apps/details?id=br.com.racsystems.meucatalogo"
              rel="noopener noreferrer"
              target="_blank"
            >
              <DonwloadButton>
                <ImgButton src="./Imagens/logo-google-playstore.svg" />
                <TextButtons>
                  <SpanButtons>Disponível na </SpanButtons>
                  <SpanButtons>
                    <BlackText>PlayStore</BlackText>
                  </SpanButtons>
                </TextButtons>
              </DonwloadButton>
            </Links>
            <Links
              href={process.env.REACT_APP_URL_WEB}
              rel="noopener noreferrer"
              target="_blank"
            >
              <DonwloadButton>
                <ImgButton src="./Imagens/laptop-outline.svg" />
                <TextButtons>
                  <SpanButtons>Disponível na </SpanButtons>
                  <SpanButtons>
                    <BlackText>Web</BlackText>
                  </SpanButtons>
                </TextButtons>
              </DonwloadButton>
            </Links>
          </Buttons>
          <ImgSreen src="https://cdn-rac.azureedge.net/assets/site-catalogo/screen.png" />
        </Left>
        <Subtitle padding>
          Destaque seu produto numa aplicação moderna, ágil, personalizandodo
          seu jeito com a melhor qualidade.
        </Subtitle>
        <ImgSreen
          md
          src="https://cdn-rac.azureedge.net/assets/site-catalogo/screen.png"
        />
        <Buttons>
          <Links
            href="https://apps.apple.com/br/app/meu-cat%C3%A1logo/id1534367526"
            rel="noopener noreferrer"
            target="_blank"
          >
            <DonwloadButton>
              <ImgButton src="./Imagens/logo-apple-appstore.svg" />
              <TextButtons>
                <SpanButtons>Disponível na </SpanButtons>
                <SpanButtons>
                  <BlackText>AppStore</BlackText>
                </SpanButtons>
              </TextButtons>
            </DonwloadButton>
          </Links>
          <Links
            href="https://play.google.com/store/apps/details?id=br.com.racsystems.meucatalogo"
            rel="noopener noreferrer"
            target="_blank"
          >
            <DonwloadButton>
              <ImgButton src="./Imagens/logo-google-playstore.svg" />
              <TextButtons>
                <SpanButtons>Disponível na </SpanButtons>
                <SpanButtons>
                  <BlackText>PlayStore</BlackText>
                </SpanButtons>
              </TextButtons>
            </DonwloadButton>
          </Links>
        </Buttons>
      </Content>
    </DivBanner>
  );
};

export default Banner;
