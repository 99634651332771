import styled from 'styled-components';

import colors from 'styles/colors';

export const TooltipContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 4px;
`;

export const TooltipTitle = styled.span`
  background: ${colors.error};
  border-radius: 0.4rem;
  bottom: calc(100% + 0.8rem);
  color: #fff;
  font-size: 0.7rem;
  opacity: 0;
  padding: 0.8rem;
  position: absolute;
  right: 0;
  text-align: center;
  transition: opacity 0.4s ease;
  visibility: hidden;
  width: 130px;
  z-index: 19;

  &::before {
    border-color: ${colors.error} transparent;
    border-style: solid;
    border-width: 0.6rem 0.6rem 0 0.6rem;
    content: '';
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translateX(-50%);
  }

  ${TooltipContainer}:hover & {
    opacity: 1;
    visibility: visible;
  }

  @media (min-width: 700px) {
    transform: translateX(0%);

    &::before {
      left: 100%;
      transform: translateX(-130%);
    }
  }
`;
