import React, { useEffect } from 'react';

import Footer from '../Footer';
import Header from '../Header';
import {
  Bold,
  Br,
  Content,
  DivideFooter,
  P,
  Subtitle,
  TermosContent,
  Title,
} from './styles';

const Termos: React.FC = () => {
  useEffect(() => {
    window.scroll(0, 0);
  });

  return (
    <>
      <Header classe="header-content" />
      <TermosContent>
        <Title>Termos de Uso</Title>
        <Content>
          <Subtitle>1. DA EMPRESA DE CONTEÚDO</Subtitle>
          <P>
            A empresa&nbsp;
            <Bold underline>RAC SYSTEMS CONSULTORIA E DESENVOLVIMENTO,</Bold>
            &nbsp; inscrita no CNPJ sob o n 14.207.514/0001-27, localizada na
            Avenida Romeu Strazzi, n 325, salas 409, 410 e 411, doravante
            denominada&nbsp;
            <Bold>&quot;CONTRATADA&quot;,</Bold>
            &nbsp;fornecerá ao cliente, doravante denominado&nbsp;
            <Bold>“ASSINANTE”, o serviço de catálogo de imagens digital,</Bold>
            &nbsp;de acordo com o pacote de assinatura adquirido pelo mesmo, e
            pelas condições dispostas neste instrumento.
          </P>

          <P>
            A aplicação&nbsp;
            <Bold>MEU CATÁLOGO</Bold>
            ,&nbsp;denominada de&nbsp;
            <Bold>“PRODUTO”</Bold>
            ,&nbsp;é de propriedade&nbsp;
            <Bold>RACSYSTEMS CONSULTORIA E DESENVOLVIMENTO</Bold>
            .&nbsp;Assim, ao solicitar o serviço, você concorda&nbsp;
            <Bold>expressamente e integralmente</Bold>
            &nbsp;com os termos de uso e as condições estabelecidas neste
            instrumento.
          </P>

          <Subtitle>2. DO CONTEÚDO</Subtitle>
          <P>
            2.1 O conteúdo do&nbsp;
            <Bold>PRODUTO</Bold>
            &nbsp;é de competência do&nbsp;
            <Bold>ASSINANTE</Bold>
            ,&nbsp;que deverá fazer uploadsde produtos e suas configurações
            (descrição, preço, imagens, etc), de acordo com suas necessidades e
            segundo os termos do pacote de assinatura adquirido pelo mesmo.
          </P>

          <P>
            2.2 A&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;se reserva o direitoexclusivoe inegociável demodificar o
            conteúdo do&nbsp;
            <Bold>PRODUTO</Bold>
            ,&nbsp;sempre procurando manter a qualidade disponívelde forma a
            melhor atender seus clientes
          </P>

          <Subtitle>3.DA ASSINATURA</Subtitle>
          <P>
            3.1. A&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;disponibiliza a participaçãoe acesso do&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;ao&nbsp;
            <Bold>PRODUTO</Bold>
            &nbsp;através de trêsplanos de assinatura denominados:&nbsp;
            <Bold>“FREE”</Bold>
            ,&nbsp;
            <Bold>“FLEX”</Bold>
            ,&nbsp;e&nbsp;
            <Bold>“PREMIUM”</Bold>
            .
            <Br />
            <Br />
            3.1. No plano&nbsp;
            <Bold>“FREE”</Bold>
            ,&nbsp;o&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;terá acesso limitado relacionado aquantidade de produtos que
            podem ser inseridos na plataforma. Para os&nbsp;
            <Bold>ASSINANTES</Bold>
            &nbsp;que tenham optado pelo plano de assinatura denominado&nbsp;
            <Bold>“FREE”</Bold>
            ,&nbsp;será permitida a utilização de&nbsp;
            <Bold>até 200 produtos na base de dados (MEU CATÁLOGO)</Bold>
            ,&nbsp;podendo ser produtos ativos ou inativos.
          </P>

          <P>
            3.2. No Plano&nbsp;
            <Bold>“FLEX”</Bold>
            ,&nbsp;o&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;terá acesso ilimitado relacionados a inserção de produtos,
            além de poder contar ainda com a exibição de preços no produto
            (tabela de preços), conforme definição de parâmetros (Painel -&gt;
            Configurações -&gt; Aba Geral -&gt; Opção Liberar opção de preço).
          </P>

          <P>
            3.3. No Plano&nbsp;
            <Bold>“PREMIUM”</Bold>
            ,&nbsp;o&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;terá acesso ilimitado na inserção de produtos, tabela de
            preços, além do fornecimento de um banco de imagens&nbsp;
            <Bold>
              (Este recurso é específico e exclusivo para distribuidoras de
              medicamentos)
            </Bold>
            .
          </P>

          <P>
            3.4 Ao adquirir a Assinatura, o&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;terá acesso irrestrito a todos os recursos disponíveis em seu
            plano,&nbsp;
            <Bold>
              apenas e tão somente enquanto a Assinatura estiver ativa
            </Bold>
            .&nbsp;Em caso de cancelamento do serviço pelo&nbsp;
            <Bold>ASSINANTE</Bold>
            ,&nbsp;antes do final do período de assinatura, não ensejará
            restrição de acesso aos recursos do&nbsp;
            <Bold>PRODUTO</Bold>
            ,&nbsp;podendo ele continuar a utilizá-lo até o prazo final do
            período anteriormente escolhido e assinado.
          </P>

          <Subtitle>4. DAS TARIFAS E MEIOS DE PAGAMENTO</Subtitle>
          <P>4.1 Via cartão de crédito recorrente.</P>

          <Subtitle>5 ATUALIZAÇÃO</Subtitle>
          <P>
            5.1 A&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;se reserva no direito de disponibilizar novos recursos na
            ferramenta,&nbsp;
            <Bold>
              sem necessidade de prévio comunicado aos usuários do catálogo
              digital, bem como sem possibilidade de indenização ao ASSINANTE
            </Bold>
            .
          </P>

          <Subtitle>6. TERMOS BÁSICOS</Subtitle>
          <P>
            6.1 A&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;não tem obrigação de controlar e não controla, autoriza e/ou
            se responsabiliza caso o&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;venha a utilizar o objeto do presente Termo de Uso para
            quaisquer fins ou meios: (i) ilegais, difamatórios,
            discriminatórios, ofensivos, obscenos, injuriosos, caluniosos,
            violentos, ou de assédio, ameaça ou uso de falsa identidade; (ii)
            cujo conteúdo seja ilegal ou em violação da moral, bons costumes ou
            da ordem pública
          </P>

          <P>
            6.2. O&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;não deverá, bem como não poderá usar o&nbsp;
            <Bold>PRODUTO</Bold>
            &nbsp;para qualquer propósito ilegal ou não autorizado, restando
            acordado desde já que se compromete a indenizar a&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;e/ou terceiros prejudicados direta e/ou indiretamente, por
            perdas e danos decorrentes de descumprimento das obrigações
            previstas no presente Termo de Uso e eventuais políticas previstas
            no portal da CONTRATADA, bem como pela má utilização, utilização
            inadequada dos serviços oferecidos pela mesma, pela violação de
            quaisquer leis ou direitos de terceiros, pela utilização do objeto
            deste Termo de Uso para fins difamatórios, discriminatórios,
            ofensivos, obscenos, injuriosos, caluniosos, violentos, ou de
            assédio, ameaça ou uso de falsa identidade ou em violação da mora,
            bons costumes ou da ordem pública.
          </P>

          <P>
            6.3. É expressamente vedado ao&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;alterar, adaptar ou invadir o&nbsp;
            <Bold>PRODUTO</Bold>
            ,&nbsp;ou modificar/criar outro aplicativo que afirme falsamente
            estar ligado ao&nbsp;
            <Bold>PRODUTO</Bold>
            ,&nbsp;sob pena de responder civil e criminalmente por tais atos
            e/ou atitudes, mesmo que a&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;e/ou terceiros não venham a experimentar prejuízos.
          </P>

          <P>
            6.4. É expressamente vedado ao&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;representar ou fazer-se representar a&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;em qualquer ato, fato, instância, local, enfim, é vedado atuar
            em nome da&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;em toda e qualquer circunstância, sob pena de responder civil
            e criminalmente por tais atos e/ou atitudes, mesmo que esta não
            venha experimentar prejuízos.
          </P>

          <Subtitle>7. DO CANCELAMENTO DO SERVIÇO</Subtitle>
          <P>
            7.1. Caso o&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;não possua mais interesse em acessar os serviços do&nbsp;
            <Bold>PRODUTO</Bold>
            ,&nbsp;deverá cancelar o serviço acessando as configurações do
            painel (Painel -&gt; Configurações -&gt; Aba Plano -&gt; Botão
            Cancelar e fazer o cancelamento da assinatura.
          </P>

          <P>
            7.2. Caso o&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;solicite o cancelamento após a cobrança mensal, o cancelamento
            será processado no mês subsequente.
          </P>

          <P>
            7.3. O pedido de cancelamento não procedido nos termos, prazos e
            canais acima não implica a desativação do serviço, tampouco a
            devolução dos valores cobrados pelos serviços prestados no período.
          </P>

          <Subtitle>8. CONDIÇÕES GERAIS</Subtitle>
          <P>
            8.1. A&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;se reserva no direito de modificar ou encerrar os serviços
            do&nbsp;
            <Bold>PRODUTO</Bold>
            &nbsp;sem motivo algum, sem aviso prévio e a qualquer momento, sem
            qualquer direito à indenização ao&nbsp;
            <Bold>ASSINANTE</Bold>
            ,&nbsp;a qualquer título que seja.
          </P>

          <P>
            8.2. A&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;se reserva no direito de alterar as condições estabelecidas
            neste Termo de Uso a qualquer momento, sem prévio aviso e sem
            qualquer direito à indenização ao&nbsp;
            <Bold>ASSINANTE</Bold>
            ,&nbsp;a qualquer título que seja. Em sendo as alterações
            significativas, segundo análise da&nbsp;
            <Bold>CONTRATANTE</Bold>
            ,&nbsp;esta notificará todos os clientes através do aplicativo.
          </P>

          <P>
            8.3. A&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;se reserva no direito de encerrar o serviço a qualquer cliente
            que viole direitos autorais ou qualquer parte deste Termo de Uso,
            sem prejuízo de buscar reparação por danos materiais e/ou morais
            experimentados pela mesma.
          </P>

          <P>
            8.4. Apesar do conteúdo do&nbsp;
            <Bold>PRODUTO</Bold>
            &nbsp;estar normalmente disponível, em alguns momentos o serviço
            poderá ficar offline por motivo de manutenção, upgrades, reparos de
            emergência, ou por falha de links de comunicação e equipamento,
            ficando desde já estabelecido que tais fatos estão fora do controle
            da&nbsp;
            <Bold>CONTRATADA.</Bold>
          </P>

          <P>
            8.5. A&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;se reserva no direito de excluir qualquer parte do conteúdo a
            qualquer momento, por qualquer motivo e sem aviso prévio, sem
            qualquer direito à indenização ao&nbsp;
            <Bold>ASSINANTE</Bold>
            ,&nbsp;a qualquer título que seja.
          </P>

          <P>
            A&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;não será responsável, em nenhuma hipótese, por danos ocorridos
            ao&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;decorrentes de qualquer causa fora do controle razoável
            da&nbsp;
            <Bold>CONTRATADA</Bold>
            ,&nbsp;tais como, mas não limitados à:
          </P>

          <P>
            i. Divergência de dados do&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;que impossibilitem acesso ao objeto deste Termo de Uso;
          </P>

          <P>
            ii. Utilização de meios incorretos para acesso ao objeto deste Termo
            de Uso;
          </P>

          <P>
            iii. Falha de comunicação com a Internet por parte do&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;e/ou signatários;
          </P>

          <P>iv. Casos fortuitos ou de força maior.</P>

          <P>
            A&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;não se responsabiliza, ainda, por quaisquer pagamentos de
            custos, prejuízos, danos ou indenizações de qualquer espécie e/ou
            tipo ao&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;ou quaisquer terceiros a ele vinculados direta e/ou
            indiretamente à:
          </P>

          <P>
            i. Má utilização do objeto deste Termo de Uso pelo&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;e/ou terceiros a ele vinculados direta e/ou indiretamente,
            utilização em desacordo com as normas da&nbsp;
            <Bold>CONTRATADA;</Bold>
          </P>

          <P>
            ii. Fraude ou atos ilícitos relacionados a utilização do objeto
            deste Termo de Uso;
          </P>

          <P>
            iii. Incorreta identidade do&nbsp;
            <Bold>ASSINANTE;</Bold>
          </P>

          <P>
            iv. Expectativa de resultados pretendidos ou verificados na
            utilização do objeto deste Termo de Uso;
          </P>

          <P>
            v. Presença de vírus nos conteúdos disponibilizados para fins do
            objeto deste Termo de Uso.
          </P>

          <Subtitle>
            9. PROPRIEDADE INTELECTUAL, DIREITOS AUTORAIS E MARCAS
          </Subtitle>
          <P>
            9.1. O portal da&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;e todos os seus componentes (layout, marcas, logotipos,
            produtos, sistemas, denominações de serviços, programas, bases de
            dados, imagens, arquivo e demais componentes que o integrem), é
            protegido pela legislação pertinente, sendo que os direitos de
            propriedade intelectual pertencem exclusivamente à&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;ou terceiros que tenham contratos firmados com a mesma.
          </P>

          <P>
            9.2. A reprodução, distribuição, transmissão, cópia, modificação,
            publicação não autorizada, transferência de informações sem expresso
            consentimento, por escrito, seja qual for a finalidade, bem como o
            uso indevido de materiais protegidos por propriedade intelectual
            (direito autoral, marcas comerciais, patentes, etc.), constituem
            infração à legislação pertinente e ao presente Termo e ensejará o
            cancelamento dos serviços prestados pela&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;ao infrator, sem prejuízo das cominações legais pertinentes,
            bem como o dever de indenizar a&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;ou terceiros pelos prejuízos sofridos.
          </P>

          <Subtitle>10. VIGÊNCIA</Subtitle>
          <P>
            10.1. O presente Termo tem vigência por prazo indeterminado, tendo
            seu início a partir da data do Aceite Eletrônico por parte do &nbsp;
            <Bold>ASSINANTE.</Bold>
          </P>

          <P>
            10.2. Será considerado motivo justo para a rescisão imediata e
            unilateral deste Termo, por parte da&nbsp;
            <Bold>CONTRATADA</Bold>
            ,&nbsp;se a utilização do objeto deste ocorrer em violação às
            disposições do presente instrumento, ou ainda, se o&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;infringir o ordenamento jurídico vigente, sem direito a
            qualquer restituição de créditos eventuais do&nbsp;
            <Bold>ASSINANTE.</Bold>
          </P>

          <P>
            10.3. Este Termo de Uso substitui qualquer outro documento com o
            mesmo objeto e poderá ser alterado e substituído a qualquer momento
            pela&nbsp;
            <Bold>CONTRATADA</Bold>
            ,&nbsp;comprometendo-se o&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;a verificar periodicamente as alterações realizadas mediante
            acesso ao portal da&nbsp;
            <Bold>CONTRATADA.</Bold>
          </P>

          <P>
            10.4. A&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;e o&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;poderão assinar documento com o mesmo objeto do presente
            Termo, todavia, o presente Termo será aplicável para tudo o que não
            estiver disposto em contrário em eventual contrato firmado entre as
            Partes.
          </P>

          <P>
            10.5. As partes aceitam a força probante, validade e eficácia de
            comunicações eletrônicas para todos os fins e efeitos deste Termo.
          </P>

          <P>
            10.6. Este Termo obriga as partes e seus sucessores, no entanto,
            o&nbsp;
            <Bold>ASSINANTE</Bold>
            &nbsp;não poderá ceder a terceiros quaisquer direitos decorrentes
            deste Termo, em nenhuma hipótese.
          </P>

          <P>
            10.7. A&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;poderá, a qualquer tempo e a seu exclusivo critério, ceder ou
            transferir, total ou parcialmente, os direitos e obrigações
            decorrentes do presente Termo.
          </P>

          <P>
            10.8. A nulidade de quaisquer disposições do presente Termo de Uso
            não prejudicará a validade das demais. Caso qualquer disposição
            deste Termo de Uso seja anulada, declarada nula ou inexigível, no
            todo ou em parte, este documento será considerado modificado com a
            exclusão ou modificação, na extensão necessária, da disposição nula,
            anulada ou inexigível, de sorte a preservar o Termo de Uso como
            válido e, na medida do possível, consistente com a intenção original
            das partes.
          </P>

          <Subtitle>11. SEGURANÇA E SUPORTE AO ASSINANTE</Subtitle>
          <P>
            11.1. O&nbsp;
            <Bold>PRODUTO</Bold>
            &nbsp;garante a confidencialidade das informações pessoais recebidas
            como base de registro e cadastro do&nbsp;
            <Bold>ASSINANTE</Bold>
            ,&nbsp;bem como garante a não utilização indevida das mesmas.
            Entende-se por utilização indevida qualquer ação que confira fins
            diferentes daqueles autorizados pela&nbsp;
            <Bold>CONTRATADA</Bold>
            &nbsp;ao&nbsp;
            <Bold>ASSINANTE</Bold>
            .&nbsp;Além da garantia à segurança do&nbsp;
            <Bold>ASSINANTE</Bold>
            ,&nbsp;o&nbsp;
            <Bold>PRODUTO</Bold>
            &nbsp;disponibiliza canais de contato para esclarecimento de
            dúvidas, orientações específicas de navegação, críticas e sugestões.
            Pela Web, o usuário poderá tirar suas dúvidas por meio do e-mail
            contato@racsystems.com.br
          </P>

          <Subtitle>12. DO FORO DE ELEIÇÃO</Subtitle>
          <P>
            12.1 As partes elegem o foro Comarca da cidade de São Paulo-SP como
            o único competente para dirimir as questões decorrentes do presente
            Termo com renúncia expressa a qualquer outro, por mais privilegiado
            que seja.
          </P>

          <Br />
          <Br />

          {/* <P>
            Este Termo de Adesão e Uso foi atualizado e registrado no XX°
            Oficial de Registro de Títulos e Documentos da Comarca de São José
            do Rio Preto – SP, sob o microfilme n°. xxxxxx, protocolado e
            prenotado sob o n°. xxxxxx e passa a valer a partir de xx de xxxxx
            de 2021.
          </P> */}
        </Content>
      </TermosContent>

      <DivideFooter />
      <Footer classe="footer-termos" />
    </>
  );
};

export default Termos;
