import React from 'react';

import { useHistory } from 'react-router-dom';

import {
  Br,
  Button,
  DivCheckContact,
  ImgCheck,
  Message,
  MessageSmall,
} from './styles';

const CheckContact: React.FC = () => {
  const history = useHistory();
  return (
    <DivCheckContact>
      <ImgCheck />
      <Message>Contato enviado com sucesso!</Message>
      <MessageSmall>
        Já recebemos o seu e-mail e em breve entraremos em contato com você!
        <Br />
        Equipe Meu Catálogo.
      </MessageSmall>
      <Button
        onClick={() => {
          history.push('/');
        }}
      >
        Voltar ao Início
      </Button>
    </DivCheckContact>
  );
};

export default CheckContact;
