import styled from 'styled-components';

export const RadioContainer = styled.label`
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  align-items: center;
  color: #666;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  height: 20px;
  justify-content: flex-start;
  margin-right: 16px;
  margin-top: 0;
  padding-left: 32px;
  position: relative;
  user-select: none;
`;

export const RadioInput = styled.input`
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  transition: all 0.3s ease-in;
  width: 0;

  &:checked ~ .circlemark {
    background-color: #afafaf;
  }

  &:checked ~ .circlemark:after {
    opacity: 1;
  }
`;

export const RadioSpan = styled.span`
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s ease-in;
  width: 15px;

  &:after {
    background: #2e2e2e;
    border-radius: 50%;
    content: '';
    height: 9px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 3px;
    width: 9px;
  }
`;
