import React, { createContext, useCallback, useState } from 'react';

import Toast from 'components/Toast';
import { v4 } from 'uuid';

import { IToastMessage, IUseToastContext } from './index';

export const ToastContext = createContext<IUseToastContext>(
  {} as IUseToastContext,
);

const UseToastProvider: React.FC = ({ children }) => {
  const [messages, setMessages] = useState<IToastMessage[]>([]);

  const addToast = useCallback(({ title, type }: Omit<IToastMessage, 'id'>) => {
    const id = v4();

    const toast = {
      id,
      type,
      title,
    };

    setMessages(state => [...state, toast]);
  }, []);

  const removeToast = useCallback((id: string) => {
    setMessages(state => state.filter(message => message.id !== id));
  }, []);

  const handlesExported = { addToast, removeToast };

  const statesExported = {};

  return (
    <ToastContext.Provider value={{ ...handlesExported, ...statesExported }}>
      {children}
      <Toast messages={messages} />
    </ToastContext.Provider>
  );
};

export default UseToastProvider;
