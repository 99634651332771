import styled, { css } from 'styled-components';

import { Form as FormUnform } from '@unform/web';
import EPlans from 'constants/plans';

export const Container = styled.div`
  box-sizing: border-box;
  margin-bottom: -120px;

  position: relative;
  width: 100%;
`;

export const PlanHeader = styled.div`
  cursor: pointer;
  height: 80px;
  margin: 0 auto;
  max-width: 1240px;
  padding: 16px;
  position: relative;
`;

export const PlanLogo = styled.img`
  height: 100%;
`;

export const BgPlan = styled.div`
  background-color: #eee;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
`;

interface IFormProps {
  planFree?: EPlans;
}

export const Form = styled(FormUnform)<IFormProps>`
  box-sizing: border-box;
  display: grid;
  height: auto;
  max-width: 1240px;
  padding: 16px;
  position: relative;
  row-gap: 16px;
  width: 100%;
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 0;

    max-width: 900px;
    margin: 24px auto;

    ${props =>
      props.planFree === EPlans.FREE &&
      css`
        grid-template-columns: 1fr;
        max-width: 500px;
      `};
  }
`;

export const ContentClient = styled.div`
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 16px;
  width: 100%;
  @media screen and (min-width: 1024px) {
    padding: 32px;
  }
`;

export const ContentPayment = styled.div`
  background: #fff;
  border: 1px solid #dedede;
  border-radius: 8px;
  box-sizing: border-box;
  height: fit-content;
  padding: 16px;
  width: 100%;
  @media screen and (min-width: 1024px) {
    padding: 32px;
  }
`;

export const ClientType = styled.div``;

export const Label = styled.label`
  display: flex;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 8px;
`;

export const ClientTypeOptions = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ClientTypeOption = styled.div`
  width: 50%;
`;

export const ClientField = styled.div`
  margin-top: 16px;
`;

interface IPlanRowProps {
  gridTemplateColumns?: string;
}

export const PlanRow = styled.div<IPlanRowProps>`
  @media screen and (min-width: 375px) {
    column-gap: 8px;
    display: grid;
    grid-template-columns: ${props =>
      props.gridTemplateColumns ? props.gridTemplateColumns : 'repeat(2, 1fr)'};
  }
`;

export const ClientSubtitle = styled.p`
  display: flex;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: -4px;
  margin-top: 24px;
`;

export const TermsContainer = styled.div`
  box-sizing: border-box;
  color: #888;
  display: flex;
  font-size: 0.6rem;
  margin-top: 16px;
  text-align: justify;
  width: 100%;
`;

export const PlanName = styled.h4`
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 0;
`;

export const PlanType = styled.h4`
  font-size: 15px;
  margin-bottom: 0;
  margin-top: 16px;
  @media screen and (min-width: 375px) {
    margin-top: 0;
  }
`;

export const Subtitle = styled.p`
  font-size: 14px;
`;

export const CardRow = styled.div`
  column-gap: 8px;
  display: grid;
  grid-template-columns: 1fr 60px;
`;

export const CardBrand = styled.div`
  align-items: center;
  align-self: flex-end;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`;

export const CardBrandImage = styled.img`
  height: 40px;
  width: 50px;
`;

export const ActionButton = styled.button`
  align-items: center;
  background-color: #354649;
  border: 0;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  height: 40px;
  justify-content: center;
  margin: 24px auto 0;
  padding: 0 24px;
  @media screen and (min-width: 1240px) {
    &:hover {
      background-color: #394149;
    }
  }
`;

export const PlanAmount = styled.div`
  align-items: center;
  border: 1px solid #dedede;
  border-radius: 8px;
  box-sizing: border-box;
  color: #555 !important;
  display: flex;
  flex: 1;
  font-size: 14px !important;
  height: 40px;
  padding: 0 8px;
  position: relative;
  width: 100%;
`;
