import React, { createContext, useCallback } from 'react';

import useToast from 'hooks/useToast';
import httpClient from 'services/httpClient';
import ICreateContactPost from 'services/httpClient/requests/contato/ICreateContactPost';

import { ISendContactDTO, IUseContactContext } from './index';

export const ContactContext = createContext<IUseContactContext>(
  {} as IUseContactContext,
);

const UseContactProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();

  const send = useCallback(
    async ({ data, functions }: ISendContactDTO): Promise<void> => {
      try {
        const payload: ICreateContactPost = {
          email: data.email,
          company: data.companyName,
          name: data.name,
          phone: data.phone,
          role: data.office,
          observation: data.observation,
        };
        await httpClient.post('contacts', payload);
        functions.stopLoading();
        functions.onSuccess();
      } catch (err) {
        functions.stopLoading();
        addToast({
          title: 'Não foi possível enviar o contato',
          type: 'error',
        });
      }
    },
    [addToast],
  );

  const handlesExported = {
    send,
  };

  const statesExported = {};

  return (
    <ContactContext.Provider value={{ ...handlesExported, ...statesExported }}>
      {children}
    </ContactContext.Provider>
  );
};

export default UseContactProvider;
