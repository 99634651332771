import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';

import { FiAlertCircle } from 'react-icons/fi';

import { useField } from '@unform/core';
import colors from 'styles/colors';

import { Container, Error, IInputProps, Input } from './styles';

export type IInputStylesProps = IInputProps;

interface IComponentInputSimpleProps
  extends InputHTMLAttributes<HTMLInputElement> {
  inputStyles?: IInputStylesProps;
  name: string;
}

const ComponentInputSimple: React.FC<IComponentInputSimpleProps> = ({
  name,
  inputStyles = {},
  ...props
}) => {
  const { defaultValue, error, fieldName, registerField } = useField(name);

  const inputRef = useRef<HTMLInputElement>(null);

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isFilled, setIsFilled] = useState<boolean>(false);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);

    setIsFilled(!!inputRef.current?.value);
  };

  return (
    <Container isErrored={!!error} isFilled={isFilled} isFocused={isFocused}>
      <Input
        defaultValue={defaultValue}
        id={fieldName}
        maxLength={191}
        onBlur={handleInputBlur}
        onFocus={handleInputFocus}
        ref={inputRef}
        type="text"
        {...props}
        {...inputStyles}
      />

      {!!error && (
        <Error title={error}>
          <FiAlertCircle color={colors.error} size={18} />
        </Error>
      )}
    </Container>
  );
};

export default ComponentInputSimple;
