import styled, { css } from 'styled-components';

import colors from 'styles/colors';

interface IScreen {
  md?: boolean;
}

export const Contact = styled.div<IScreen>`
  background-color: #37aa9c;
  width: 100%;
  @media only screen and (max-width: 450px) {
    display: grid;
    justify-items: center;
    align-items: center;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    display: grid;
    justify-items: center;
    align-items: center;
    padding-top: 30px;
  }
  @media only screen and (min-width: 1024px) {
    height: 100vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 100px;
  }
`;

export const SendContact = styled.div<IScreen>`
  align-items: center;
  border: 2px solid #fff;
  border-radius: 10px;
  display: grid;
  height: 700px;
  padding: 30px;
  @media only screen and (min-width: 1024px) {
    display: none;
  }
  ${props =>
    props.md &&
    css`
      @media only screen and (max-width: 1023px) {
        display: none;
      }
      @media only screen and (max-width: 1023px) and (min-width: 451px) {
        width: 420px;
      }
      @media only screen and (min-width: 1024px) {
        width: 420px;
        display: block;
      }
    `}
`;

export const ContentContact = styled.div`
  @media only screen and (max-width: 450px) {
    width: 85%;
  }
  @media only screen and (min-width: 451px) {
    width: 50%;
  }
`;

export const Title = styled.p`
  color: #fff;
  margin-bottom: 0;

  @media only screen and (max-width: 450px) {
    font-size: 25px;
    text-align: center;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    font-size: 30px;
    text-align: center;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 33px;
    text-align: right;
  }
`;

export const Subtitle = styled.p`
  color: #fff;
  margin-bottom: 0;
  margin-top: 0;

  @media only screen and (max-width: 450px) {
    font-size: 16px;
    padding-top: 20px;
    padding-bottom: 50px;
    text-align: center;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    font-size: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 20px;
    text-align: right;
  }
`;

export const FormTitle = styled.p`
  color: #fff;
  margin-bottom: 0;
  text-align: center;
  @media only screen and (max-width: 450px) {
    ont-size: 25px;
    padding-bottom: 70px;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    font-size: 35px;
    padding-bottom: 70px;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 35px;
    padding-bottom: 30px;
  }
`;

export const FormContent = styled.div`
  margin: 0 0 40px 0;
`;

export const ErrorMessage = styled.p`
  color: #fff1d1fa;
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0;
  margin-right: 1px;
  margin-top: 4px;
  text-align: end;
`;

export const BtnSend = styled.button`
  background-color: #37aa9c;
  border: 2px solid #e3d985;
  border-radius: 10px;
  color: #e3d985;
  cursor: pointer;
  font-family: 'Poppins', sans-serif !important;
  font-size: 1.1em;
  font-weight: 300;
  margin-top: 1.5rem;
  outline: none;
  padding: 0.3rem 1rem;
  text-align: center;
  text-decoration: none;
  transition: all ease-in;
  transition-duration: 0.4s;
  width: 100%;
`;

export const InputContainer = styled.div`
  align-items: center;
  border-bottom: 1px solid #fff;
  box-shadow: none;
  box-sizing: content-box;
  display: flex;
  font-size: 16;
  font-weight: 400;
  height: 2rem;
  position: relative;
`;

export const Input = styled.input`
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: #fff;
  flex: 1;
  outline: none;
`;

export const TooltipContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 4px;
`;

export const TooltipTitle = styled.span`
  background: ${colors.secondary};
  border-radius: 0.4rem;
  bottom: calc(100% + 0.6rem);
  color: ${colors.white};
  font-size: 0.8rem;
  opacity: 0;
  padding: 0.8rem;
  position: absolute;
  right: -6px;
  text-align: center;
  transition: opacity 0.4s ease;
  visibility: hidden;
  width: 130px;
  z-index: 19;
  &::before {
    border-color: ${colors.secondary} transparent;
    border-style: solid;
    border-width: 0.6rem 0.6rem 0 0.6rem;
    content: '';
    /* left: 50%; */
    position: absolute;
    top: 100%;
    /* transform: translateX(-50%); */
    left: 100%;
    transform: translateX(-130%);
  }
  ${TooltipContainer}:hover & {
    opacity: 1;
    visibility: visible;
  }
  @media (min-width: 700px) {
    transform: translateX(0%);
    &::before {
      left: 100%;
      transform: translateX(-130%);
    }
  }
`;
