import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { PulseLoader } from 'react-spinners';

import { FormHandles, Scope, SubmitHandler } from '@unform/core';
import cardValidator from 'card-validator';
import Footer from 'components/Footer';
import ComponentInputCardNumber from 'components/Input/CardNumber';
import ComponentInputCheckbox from 'components/Input/Checkbox';
import ComponentInputDocument from 'components/Input/Document';
import ComponentInputExpiration from 'components/Input/Expiration';
import ComponentInputPhone from 'components/Input/Phone';
import { IOption } from 'components/Input/Radio';
import ComponentInputSimple, {
  IInputStylesProps,
} from 'components/Input/Simple';
import ComponentModalSuccess, {
  IComponentSuccessRefProps,
} from 'components/Modal/Success';
import cardBrands from 'constants/cardBrands';
import EPlans from 'constants/plans';
import usePayment from 'hooks/usePayment';
import { RadioContainer, RadioInput, RadioSpan } from 'styles/radioButton';
import formatter from 'utils/formatter';
import helper from 'utils/helper';
import validator from 'utils/validator';
import * as Yup from 'yup';

import {
  ActionButton,
  BgPlan,
  CardBrand,
  CardBrandImage,
  CardRow,
  ClientField,
  ClientType,
  ClientTypeOptions,
  Container,
  ContentClient,
  ContentPayment,
  Form,
  Label,
  PlanAmount,
  PlanHeader,
  PlanLogo,
  PlanName,
  PlanRow,
  PlanType,
  Subtitle,
  TermsContainer,
} from './styles';

enum ETypesPerson {
  COMPANY = 'company',
  PERSON = 'person',
}

interface IPlanLocation {
  amount: string;
  paymentType?: string;
  plan: EPlans;
  planId: number;
}

interface IFormData {
  card?: {
    cvv: string;
    expiration: string;
    holder: string;
    number: string;
  };
  city: string;
  companyName?: string;
  complement?: string;
  document: string;
  email: string;
  name: string;
  neighborhood: string;
  number: string;
  password: string;
  passwordConfirm: string;
  phone: string;
  politics: boolean;
  state: string;
  street: string;
  terms: boolean;
  type: IOption;
  zipcode: string;
}

const typePersonOptions: IOption<ETypesPerson>[] = [
  {
    label: 'Pessoa física',
    value: ETypesPerson.PERSON,
  },
  {
    label: 'Pessoa jurídica',
    value: ETypesPerson.COMPANY,
  },
];

const Plan: React.FC = () => {
  const { createPayment } = usePayment();

  const history = useHistory();

  const location = useLocation<IPlanLocation | undefined>();

  const componentSimpleInput = useMemo<IInputStylesProps>(
    () => ({
      textTransform: 'uppercase',
    }),
    [],
  );

  const formRef = useRef<FormHandles>(null);

  const componentModalSuccessRef = useRef<IComponentSuccessRefProps>(null);

  const [cardBrand, setCardBrand] = useState<string>(cardBrands.default);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [typePerson, setTypePerson] = useState<IOption<ETypesPerson>>(
    typePersonOptions[0],
  );

  const planAmount: string | undefined = location.state?.amount;
  const planId: number | undefined = location.state?.planId;
  const planType: EPlans | undefined = location.state?.plan;
  const paymentType: string | undefined = location.state?.paymentType;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!planType) {
      history.push('/');
    }
  }, [history, planType]);

  function goHome() {
    history.push('/');
  }

  const handleSubmit: SubmitHandler<IFormData> = useCallback(
    async data => {
      try {
        setIsLoading(true);
        formRef.current?.setErrors({});

        const schemaValidation = Yup.object().shape({
          companyName: Yup.string().when('$typePerson', {
            is: typePersonYup => typePersonYup.value === ETypesPerson.COMPANY,
            then: Yup.string().required('Digite a sua razão social'),
          }),
          name: Yup.string().required('Digite o seu nome completo'),
          document: Yup.string()
            .test(
              'document',
              typePerson.value === ETypesPerson.COMPANY
                ? 'Informe um CNPJ válido'
                : 'Informe um CPF válido',
              document => {
                if (!document) {
                  return true;
                }

                if (typePerson.value === ETypesPerson.COMPANY) {
                  const isCnpjValid = validator.cnpj(document);

                  return isCnpjValid;
                }

                const isCpfValid = validator.cpf(document);

                return isCpfValid;
              },
            )
            .required(
              typePerson.value === ETypesPerson.COMPANY
                ? 'Digite o seu CNPJ'
                : 'Digite o seu CPF',
            ),
          phone: Yup.string()
            .test('phone', 'Digite um telefone válido', phone => {
              if (!phone) {
                return true;
              }

              const isPhoneValid = validator.phone(phone);

              return isPhoneValid;
            })
            .required('Digite seu telefone'),
          email: Yup.string()
            .email('Endereço de e-mail inválido')
            .required('Digite um e-mail no formato: seunome@exemplo.com'),
          password: Yup.string().required('Digite uma senha'),
          passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password')], 'Confirmação de senha incorreta')
            .required('Confirme a senha digitada'),
          card: Yup.object().when('$planType', {
            is: planTypeYup => planTypeYup !== EPlans.FREE,
            then: Yup.object().shape({
              number: Yup.string()
                .test(
                  'cardNumberValid',
                  'Digite um número de cartão de crédito válido',
                  cardNumber => {
                    const cardNumberValid = cardValidator.number(cardNumber);

                    return cardNumberValid.isValid;
                  },
                )
                .test('CardNumberBrand', 'Bandeira não aceita', cardNumber => {
                  const cardNumberValid = cardValidator.number(cardNumber);

                  const cardNumberBrand = cardNumberValid.card?.type;

                  if (
                    cardNumberBrand !== 'american-express' &&
                    cardNumberBrand !== 'elo' &&
                    cardNumberBrand !== 'hipercard' &&
                    cardNumberBrand !== 'mastercard' &&
                    cardNumberBrand !== 'visa'
                  ) {
                    return false;
                  }

                  return true;
                })
                .required('Digite um número de cartão de crédito válido'),
              holder: Yup.string().required(
                'Digite o nome como aparece no cartão',
              ),
              expiration: Yup.string()
                .test(
                  'cardExpiration',
                  'Digite uma data de validade válida',
                  cardExpiration => {
                    if (!cardExpiration) {
                      return true;
                    }

                    const cardExpirationSplit = cardExpiration.split('/');

                    const date = helper.makeDate({
                      month: cardExpirationSplit[0],
                      year: cardExpirationSplit[1],
                    });

                    const isDateValid = validator.date(date);

                    return isDateValid;
                  },
                )
                .required('Digite uma data de validade válida'),
              cvv: Yup.string()
                .min(3, 'Informe um código de segurança válido')
                .required('Digite o código de segurança'),
            }),
          }),
          terms: Yup.bool().oneOf([true]),
        });

        await schemaValidation.validate(data, {
          abortEarly: false,
          context: { typePerson, planType },
        });

        const cardExpiration = data.card?.expiration.split('/');

        await createPayment({
          data: {
            companyName: data.companyName,
            document: data.document,
            email: data.email,
            name: data.name,
            password: data.password,
            phone: data.phone,
            plan: planType as EPlans,
            planId: planId as number,
            card:
              data.card && cardExpiration
                ? {
                    cvv: data.card.cvv,
                    expiration: helper.makeDate({
                      month: cardExpiration[0],
                      year: cardExpiration[1],
                    }),
                    holder: data.card.holder,
                    number: data.card.number,
                  }
                : undefined,
          },
          functions: {
            openModalSuccess: () => {
              componentModalSuccessRef.current?.open();
            },
            resetForm: () => {
              formRef.current?.reset();
              setTypePerson(typePersonOptions[0]);
            },
            stopLoading: () => {
              setIsLoading(false);
            },
          },
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = helper.getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        setIsLoading(false);
      }
    },
    [createPayment, planId, planType, typePerson],
  );

  const handleOnChangeInputRadio = () => {
    if (typePerson.value === ETypesPerson.PERSON) {
      setTypePerson(typePersonOptions[1]);
    }

    if (typePerson.value === ETypesPerson.COMPANY) {
      setTypePerson(typePersonOptions[0]);
    }
  };

  const handleOnChangeCardNumber = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const text = event.target.value;

      const textFormatted = formatter.cardNumber({ value: text });

      formRef.current?.setFieldValue('card.number', textFormatted);

      if (textFormatted.length >= 17) {
        const cardInfo = cardValidator.number(textFormatted);

        if (!cardInfo.card) {
          setCardBrand(cardBrands.default);
          return;
        }

        const cardType: string = cardInfo.card.type;

        const cardBrandSelected = cardBrands[cardType] || cardBrands.default;
        setCardBrand(cardBrandSelected);
      } else {
        setCardBrand(cardBrands.default);
      }
    },
    [],
  );

  return (
    <>
      <Container>
        <BgPlan />

        <PlanHeader onClick={goHome}>
          <PlanLogo src="https://cdn-rac.azureedge.net/assets/site-catalogo/logo-originalHorizontal.png" />
        </PlanHeader>

        <Form onSubmit={handleSubmit} planFree={planType} ref={formRef}>
          <ContentClient>
            <ClientType>
              <Label>Qual o tipo de cadastro</Label>

              <ClientTypeOptions>
                <RadioContainer>
                  Pessoa física
                  <RadioInput
                    defaultChecked={typePerson.value === ETypesPerson.PERSON}
                    name="type"
                    onChange={handleOnChangeInputRadio}
                    type="radio"
                    value={ETypesPerson.PERSON}
                  />
                  <RadioSpan className="circlemark" />
                </RadioContainer>

                <RadioContainer>
                  Pessoa jurídica
                  <RadioInput
                    defaultChecked={typePerson.value === ETypesPerson.COMPANY}
                    name="type"
                    onChange={handleOnChangeInputRadio}
                    type="radio"
                    value={ETypesPerson.COMPANY}
                  />
                  <RadioSpan className="circlemark" />
                </RadioContainer>
              </ClientTypeOptions>
            </ClientType>

            {typePerson.value === ETypesPerson.COMPANY && (
              <ClientField>
                <Label>Razão social</Label>
                <ComponentInputSimple name="companyName" />
              </ClientField>
            )}

            <ClientField>
              <Label>Nome completo</Label>
              <ComponentInputSimple name="name" />
            </ClientField>

            <PlanRow>
              <ClientField>
                <Label>
                  {typePerson.value === ETypesPerson.COMPANY ? 'CNPJ' : 'CPF'}
                </Label>
                <ComponentInputDocument name="document" />
              </ClientField>

              <ClientField>
                <Label>Telefone</Label>
                <ComponentInputPhone name="phone" />
              </ClientField>
            </PlanRow>

            <ClientField>
              <Label>E-mail</Label>
              <ComponentInputSimple name="email" />
            </ClientField>

            <ClientField>
              <Label>Senha</Label>
              <ComponentInputSimple name="password" type="password" />
            </ClientField>

            <ClientField>
              <Label>Confirmar senha</Label>
              <ComponentInputSimple name="passwordConfirm" type="password" />
            </ClientField>

            {planType === EPlans.FREE && (
              <>
                <TermsContainer>
                  <ComponentInputCheckbox
                    label="Declaro que li, aceito e concordo com os temos de uso e
                políticas de privacidade do site."
                    name="terms"
                  />
                </TermsContainer>

                <TermsContainer>
                  Você pode alterar suas escolhas a qualquer momento em suas
                  configurações de conta. O plano é renovado automaticamente, a
                  não ser que você cancele. A renovação será realizada
                  utilizando os preços regulares, no mesmo ciclo indicado
                  durante a compra e com a mesma forma de pagamento. Você pode
                  alterar essas configurações ou cancelar a qualquer hora, antes
                  da cobrança, no Painel Administrativo do Cliente.
                </TermsContainer>
              </>
            )}

            {planType === EPlans.FREE && (
              <ActionButton type="submit">
                {isLoading ? (
                  <PulseLoader color="#fff" size={7} />
                ) : (
                  'Efetuar cadastro'
                )}
              </ActionButton>
            )}
          </ContentClient>

          {planType !== EPlans.FREE && (
            <ContentPayment>
              <PlanRow>
                <PlanName>
                  <Label>Plano selecionado</Label>
                  {planType}
                </PlanName>

                <PlanType>
                  <Label>Tipo de pagamento</Label>
                  {paymentType}
                </PlanType>
              </PlanRow>

              <Subtitle>
                Preencha os campos abaixo para realizar o pagamento
              </Subtitle>

              <Scope path="card">
                <CardRow>
                  <ClientField>
                    <Label>Nº do cartão</Label>
                    <ComponentInputCardNumber
                      name="number"
                      onChange={handleOnChangeCardNumber}
                    />
                  </ClientField>

                  <CardBrand>
                    <CardBrandImage src={cardBrand} />
                  </CardBrand>
                </CardRow>

                <ClientField>
                  <Label>Nome impresso no cartão</Label>
                  <ComponentInputSimple
                    inputStyles={componentSimpleInput}
                    name="holder"
                    type="text"
                  />
                </ClientField>

                <PlanRow gridTemplateColumns="1fr 80px 1fr">
                  <ClientField>
                    <Label>Venc.</Label>
                    <ComponentInputExpiration name="expiration" />
                  </ClientField>

                  <ClientField>
                    <Label>CVV</Label>
                    <ComponentInputSimple maxLength={4} name="cvv" />
                  </ClientField>

                  <ClientField>
                    <Label>
                      {paymentType === 'Assinatura recorrente'
                        ? 'Valor mensal'
                        : 'Valor'}
                    </Label>
                    <PlanAmount>{planAmount}</PlanAmount>
                  </ClientField>
                </PlanRow>
              </Scope>

              <TermsContainer>
                <ComponentInputCheckbox
                  label="Declaro que li, aceito e concordo com os temos de uso e
                  políticas de privacidade do site."
                  name="terms"
                />
              </TermsContainer>

              <TermsContainer>
                Você pode alterar suas escolhas a qualquer momento em XXXXXX nas
                suas configurações de conta. O plano é renovado automaticamente,
                a não ser que você cancele. A renovação será realizada
                utilizando os preços regulares, no mesmo ciclo indicado durante
                a compra e com a mesma forma de pagamento. Você pode alterar
                essas configurações ou cancelar a qualquer hora, antes da
                cobrança, no Painel Administrativo do Cliente.
              </TermsContainer>

              <ActionButton type="submit">
                {isLoading ? (
                  <PulseLoader color="#fff" size={7} />
                ) : (
                  'Efetuar pagamento'
                )}
              </ActionButton>
            </ContentPayment>
          )}
        </Form>
      </Container>

      <Footer classe="footerPrincipal" />

      <ComponentModalSuccess ref={componentModalSuccessRef} />
    </>
  );
};

export default Plan;
