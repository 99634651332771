import styled from 'styled-components';

import colors from '../../styles/colors';

interface IBackdropContainerProps {
  transform?: string;
  zIndex?: string;
}

export const BackdropContainer = styled.div<IBackdropContainerProps>`
  background: ${colors.darkTransparent};
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transform: ${props => (props.transform ? props.transform : null)};
  transition: all 0s cubic-bezier(0.23, 1, 0.32, 1);
  width: 100vw;
  z-index: ${props => (props.zIndex ? props.zIndex : '11')};
  @media (min-width: 900px) {
  }
`;
