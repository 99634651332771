import styled from 'styled-components';

export const ToastContainer = styled.div`
  overflow: hidden;
  padding-right: 0.8rem;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
`;
