import styled from 'styled-components';

import Lottie from 'react-lottie';

import lottieDone from 'assets/Util/lottie-done.json';
import colors from 'styles/colors';

export const DivCheckContact = styled.div`
  align-items: center;
  background-color: ${colors.secondary};
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
`;

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: lottieDone,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const ImgCheck = styled(Lottie).attrs({
  options: defaultOptions,
  width: '90px',
  height: '90px',
})``;

export const Message = styled.h2`
  color: ${colors.white};
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  margin-top: 32px;
  max-width: 500px;
  text-align: center;
`;

export const MessageSmall = styled.p`
  color: ${colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 48px;
  margin-top: 0;
  max-width: 500px;
  text-align: center;
`;

export const Br = styled.br``;

export const Button = styled.button`
  align-items: center;
  background-color: ${colors.primary};
  border: none;
  border-radius: 8px;
  color: ${colors.white};
  cursor: pointer;
  display: flex;
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  outline: none;
  text-decoration: none;
  transition: all ease-in;
  transition-duration: 0.4s;
  width: 200px;
`;
