import React, { useEffect } from 'react';

import { FiAlertCircle, FiCheckCircle, FiInfo, FiX } from 'react-icons/fi';

import useToast, { IToastMessage } from 'hooks/useToast';

import {
  ToastClose,
  ToastContent,
  ToastDescription,
  ToastTitle,
} from './styles';

const icons = {
  info: <FiInfo size={20} />,
  error: <FiAlertCircle size={20} />,
  success: <FiCheckCircle size={20} />,
};

interface IToastElementProps {
  message: IToastMessage;
  style: any;
}

const ToastElement: React.FC<IToastElementProps> = ({ message, style }) => {
  const { removeToast } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeToast(message.id);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [message.id, removeToast]);

  return (
    <ToastContent key={message.id} style={style} type={message.type}>
      {icons[message.type || 'info']}

      <ToastDescription>
        <ToastTitle>{message.title}</ToastTitle>
      </ToastDescription>

      <ToastClose onClick={() => removeToast(message.id)} type="button">
        <FiX size={18} />
      </ToastClose>
    </ToastContent>
  );
};

export default ToastElement;
