import React from 'react';

import { useTransition } from 'react-spring';

import { IToastMessage } from 'hooks/useToast';

import Element from './Element';
import { ToastContainer } from './styles';

interface IToastProps {
  messages: IToastMessage[];
}

const Toast: React.FC<IToastProps> = ({ messages }) => {
  const messagesWithTransition = useTransition(
    messages,
    message => message.id,
    {
      from: {
        opacity: 0,
        right: '-120%',
      },
      enter: {
        opacity: 1,
        right: '0%',
      },
      leave: {
        opacity: 0,
        right: '-120%',
      },
    },
  );

  return (
    <ToastContainer>
      {messagesWithTransition.map(
        ({ item, key, props }) =>
          item && <Element key={key} message={item} style={props} />,
      )}
    </ToastContainer>
  );
};

export default Toast;
