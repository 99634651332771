import styled from 'styled-components';

export const ImgNotFound = styled.img`
  @media only screen and (max-width: 450px) {
    width: 280px;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    width: 400px;
  }
  @media only screen and (min-width: 1024px) {
    width: 1000px;
  }
`;

export const PageNotFound = styled.div`
  align-items: center;
  background-color: #37aa9c;
  display: flex;
  height: 100vh;
  justify-content: center;
`;
