import styled, { css } from 'styled-components';

import colors from 'styles/colors';

interface IContainerProps {
  isErrored: boolean;
}

export const Container = styled.label<IContainerProps>`
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  align-items: center;
  color: #666;
  cursor: pointer;
  display: flex;
  font-size: 0.7rem;
  justify-content: flex-start;
  margin-right: 16px;
  margin-top: 0;
  padding-left: 32px;
  position: relative;
  user-select: none;
  width: 100%;

  ${props =>
    props.isErrored &&
    css`
      color: ${colors.error};
    `}
`;

export const Input = styled.input`
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  transition: all 0.3s ease-in;
  width: 0;

  &:checked ~ .checkmark {
    background-color: #1a1a1a;
  }

  &:checked ~ .checkmark:after {
    opacity: 1;
  }
`;

interface ISpanProps {
  isErrored: boolean;
}

export const Span = styled.span<ISpanProps>`
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  height: 20px;
  left: 0;
  position: absolute;
  transition: all 0.2s ease-in;
  width: 20px;

  ${props =>
    props.isErrored &&
    css`
      border-color: ${colors.error};
    `}

  &:after {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    border: solid white;
    border-width: 0 2px 2px 0;
    content: '';
    height: 9px;
    left: 6px;
    opacity: 0;
    position: absolute;
    top: 3px;
    transform: rotate(45deg);
    width: 5px;
  }
`;
