import React from 'react';

import {
  BlackText,
  Description,
  FooterContent,
  ImgRedes,
  Links,
  LogoDescription,
  LogoRac,
  LogoRedes,
  PagesLink,
  RedesSociais,
  SpanButtons,
} from './styles';

interface IFooterProps {
  classe: string;
}

const Footer: React.FC<IFooterProps> = ({ classe }) => {
  return (
    <FooterContent classe={classe}>
      <LogoDescription>
        <Links
          href="http://racsys.com.br/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <LogoRac src="https://cdn-rac.azureedge.net/assets/site-catalogo/logo-rac.png" />
        </Links>
        <Description>Tecnologia para</Description>
        <Description>Atacado Distribuidor</Description>
      </LogoDescription>
      <RedesSociais>
        <Description>Siga-nos nas redes sociais</Description>
        <ImgRedes>
          <Links
            href="https://www.facebook.com/racsystems"
            rel="noopener noreferrer"
            target="_blank"
          >
            <LogoRedes src="https://cdn-rac.azureedge.net/assets/site-catalogo/logo-facebook.svg" />
          </Links>
          <Links
            href="https://www.instagram.com/racsystems/?hl=pt-br"
            rel="noopener noreferrer"
            target="_blank"
          >
            <LogoRedes src="https://cdn-rac.azureedge.net/assets/site-catalogo/logo-insta.svg" />
          </Links>
          <Links
            href="https://twitter.com/RacSystems"
            rel="noopener noreferrer"
            target="_blank"
          >
            <LogoRedes src="https://cdn-rac.azureedge.net/assets/site-catalogo/logo-twitter.svg" />
          </Links>
        </ImgRedes>
        <Description>
          <SpanButtons>
            <BlackText>2021 - </BlackText>
            Rac Systems Consultoria e Desenvolvimento
          </SpanButtons>
        </Description>
        <Description>
          <PagesLink to="/termos">Termos e Condições de Uso</PagesLink>
          <PagesLink to="/politicas">Políticas de Privacidade</PagesLink>
        </Description>
      </RedesSociais>
    </FooterContent>
  );
};

export default Footer;
