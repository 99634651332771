import { useContext } from 'react';

import { ContactContext } from './provider';

export interface ISendContactDTO {
  data: {
    companyName: string;
    email: string;
    name: string;
    observation?: string;
    office?: string;
    phone: string;
  };
  functions: {
    onSuccess(): void;
    stopLoading(): void;
  };
}

export interface IUseContactContext {
  send(data: ISendContactDTO): Promise<void>;
}

function useContact(): IUseContactContext {
  const contactContext = useContext(ContactContext);

  return contactContext;
}

export default useContact;
