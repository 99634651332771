import styled, { css } from 'styled-components';

import Tooltip from 'components/Tooltip';
import colors from 'styles/colors';

interface IContainerProps {
  isErrored?: boolean;
  isFilled: boolean;
  isFocused: boolean;
}

export const Container = styled.div<IContainerProps>`
  align-items: center;
  border: 1px solid #dedede;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  height: 40px;
  padding: 0 8px;
  position: relative;
  width: 100%;

  ${props =>
    props.isErrored &&
    css`
      border-color: ${colors.error};
    `}
  ${props =>
    props.isFocused &&
    css`
      border-color: ${colors.secondary};
      color: ${colors.secondary};
    `}
  ${props =>
    props.isFilled &&
    css`
      color: ${colors.secondary};
    `}
`;

export interface IInputProps {
  textTransform?:
    | 'capitalize'
    | 'full-width'
    | 'inherit'
    | 'initial'
    | 'lowercase'
    | 'none'
    | 'uppercase';
}

export const Input = styled.input<IInputProps>`
  box-sizing: border-box !important;
  color: #555 !important;
  flex: 1;
  font-size: 14px !important;
  padding: 0 !important;

  ${props =>
    props.textTransform &&
    css`
      text-transform: ${props.textTransform};
    `}
`;

export const Error = styled(Tooltip)``;
