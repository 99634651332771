interface IFormatterBaseDTO {
  defaultValue?: string;
  value?: string;
}

function cardNumber({ defaultValue = '', value }: IFormatterBaseDTO): string {
  if (!value) {
    return defaultValue;
  }

  const cardNumberFormatted = value
    .replace(/[^0-9]/g, '')
    .replace(/(\d{4})(\d)/, '$1 $2')
    .replace(/(\d{6})(\d)/, '$1 $2')
    .replace(/(\d{4}\s\d{6})(\d{5})(\d)/, '$1 $2 $3')
    .replace(/(\d{4})\s(\d{4})(\d{2})\s(\d{2})(\d{3})(\d)/, '$1 $2 $3$4 $5$6')
    .replace(/((\d{4}\s\d{6}\s\d{5})|(\d{4}\s\d{4}\s\d{4}))\d+?$/, '$1');

  return cardNumberFormatted;
}

function cnpj({ defaultValue = '', value }: IFormatterBaseDTO): string {
  if (!value) {
    return defaultValue;
  }

  const valueWithoutMask = removeMask({ value });

  const cnpjFormatted = valueWithoutMask
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

  return cnpjFormatted;
}

function cpf({ defaultValue = '', value }: IFormatterBaseDTO): string {
  if (!value) {
    return defaultValue;
  }

  const valueWithoutMask = removeMask({ value });

  const cpfFormatted = valueWithoutMask
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2');

  return cpfFormatted;
}

function postalCode({ defaultValue = '', value }: IFormatterBaseDTO): string {
  if (!value) {
    return defaultValue;
  }
  const postalCodeFormatted = value
    .replace(/[^0-9]/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');
  return postalCodeFormatted;
}

function expiration({ defaultValue = '', value }: IFormatterBaseDTO): string {
  if (!value) {
    return defaultValue;
  }

  const valueWithoutMask = removeMask({ value });

  const expirationFormatted = valueWithoutMask.replace(/(\d{2})(\d)/, '$1/$2');

  return expirationFormatted;
}

function phone({ defaultValue = '', value }: IFormatterBaseDTO): string {
  if (!value) {
    return defaultValue;
  }

  const valueWithoutMask = removeMask({ value });

  const phoneFormatted = valueWithoutMask
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{4,5})(\d{4})/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');

  return phoneFormatted;
}

function removeMask({ defaultValue = '', value }: IFormatterBaseDTO): string {
  if (!value) {
    return defaultValue;
  }

  const valueWithoutMask = value.replace(/[^0-9]/g, '');

  return valueWithoutMask;
}

export default {
  cardNumber,
  cnpj,
  cpf,
  expiration,
  phone,
  removeMask,
  postalCode,
};
