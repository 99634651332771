import React, { useEffect } from 'react';

import Footer from '../Footer';
import Header from '../Header';
import {
  BlackText,
  Body,
  Content,
  DivideFooter,
  Li,
  PoliticasContent,
  Subtitle,
  Title,
  Title2,
  Ul,
} from './styles';

const Politicas: React.FC = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <Header classe="header-content" />
      <PoliticasContent>
        <Title>Políticas de privacidade</Title>
        <Content>
          <Body>
            <BlackText>MEUCATALOGO</BlackText>
            {` coleta alguns dados pessoais de seus usuários.`}
          </Body>
          <Body>Proprietário e Controlador de Dados:</Body>
          <Body>Rac Systems Consultoria & Desenvolvimento Rio Preto LTDA.</Body>
          <Body>
            <BlackText>E-mail de contato do proprietário:</BlackText>
            {` contato@racsystems.com.br`}
          </Body>
          <Title2>Tipos de dados coletados</Title2>
          <Body>
            {`Entre os tipos de Dados Pessoais que o `}
            <BlackText>MEUCATALOGO</BlackText>
            {` coleta, por si ou por meio de terceiros, estão: Cookies, Dados de Uso, vários tipos de Dados, endereço de e-mail, nome, sobrenome, idade, peso, altura, sexo.`}
          </Body>
          <Body>
            Detalhes completos sobre cada tipo de Dados Pessoais coletados são
            fornecidos nas seções dedicadas desta política de privacidade ou por
            textos explicativos específicos exibidos antes da coleta de Dados.
          </Body>
          <Body>
            Os Dados Pessoais podem ser fornecidos livremente pelo Usuário ou,
            no caso de Dados de Uso, coletados automaticamente ao usar o
            MEUCATALOGO.
          </Body>
          <Body>
            {`A menos que especificado de outra forma, todos os dados solicitados pelo `}
            <BlackText>MEUCATALOGO</BlackText>
            {` são obrigatórios e a falha em fornecer esses dados pode impossibilitar que a `}
            <BlackText>MEUCATALOGO</BlackText>
            {' forneça seus serviços. Nos casos em que o '}
            <BlackText>MEUCATALOGO</BlackText>
            {` declara especificamente que alguns Dados não são obrigatórios, os Usuários são livres para não comunicar esses Dados sem consequências para a disponibilidade ou o funcionamento do Serviço.`}
          </Body>
          <Body>
            Os usuários que não tiverem certeza sobre quais dados pessoais são
            obrigatórios, entre em contato com o proprietário.
          </Body>
          <Body>
            {`Qualquer uso de cookies – ou de outras ferramentas de rastreamento – pela `}
            <BlackText>MEUCATALOGO</BlackText>
            {` ou pelos proprietários de serviços de terceiros usados pela `}
            <BlackText>MEUCATALOGO</BlackText>
            {` atende à finalidade de fornecer o Serviço exigido pelo Usuário, além de quaisquer outros fins descritos no presente documento.`}
          </Body>
          <Body>
            {` Os usuários são responsáveis por quaisquer dados pessoais de terceiros obtidos, publicados ou compartilhados por meio do`}
            <BlackText>MEUCATALOGO</BlackText>
            {` e confirmam que eles têm o consentimento de terceiros para fornecer os dados ao proprietário.`}
          </Body>
          <Title2>Modo e local de processamento dos dados</Title2>
          <Body>Métodos de processamento</Body>
          <Body>
            O proprietário toma medidas de segurança apropriadas para impedir o
            acesso não autorizado, divulgação, modificação ou destruição não
            autorizada dos dados.
          </Body>
          <Body>
            O processamento de dados é realizado por meio de computadores e / ou
            ferramentas de TI, seguindo procedimentos e modos organizacionais
            estritamente relacionados às finalidades indicadas. Além do
            Proprietário, em alguns casos, os Dados podem ser acessíveis a
            certos tipos de pessoas responsáveis, envolvidos com a operação do
            Aplicativo (administração, vendas, marketing, jurídico,
            administração do sistema) ou externos (como terceiros), provedores
            de serviços técnicos, operadoras de correspondências, provedores de
            hospedagem, empresas de TI, agências de comunicação) designados, se
            necessário, como Processadores de Dados pelo Proprietário. A lista
            atualizada dessas partes pode ser solicitada ao Proprietário a
            qualquer momento.
          </Body>
          <Body>Base jurídica do processamento</Body>
          <Body>
            O proprietário pode processar dados pessoais relacionados a usuários
            se um dos seguintes itens se aplicar:
          </Body>
          <Body>
            <Ul>
              <Li>
                Os usuários deram seu consentimento para um ou mais propósitos
                específicos. Nota: Sob algumas legislações, o Proprietário pode
                ter permissão para processar Dados Pessoais até que o Usuário
                oponha-se a tal processamento (“opt-out”), sem ter que depender
                do consentimento ou de qualquer outra das seguintes bases
                legais. Isto, no entanto, não se aplica, sempre que o
                processamento de Dados Pessoais esteja sujeito à lei de proteção
                de dados;
              </Li>
              <Li>
                O fornecimento de dados é necessário para a execução de um
                contrato com o usuário e/ou para quaisquer obrigações
                pré-contratuais do mesmo;
              </Li>
              <Li>
                O processamento é necessário para o cumprimento de uma obrigação
                legal a qual o Proprietário está sujeito;
              </Li>
              <Li>
                O processamento é necessário para os interesses legítimos da
                Proprietária ou de terceiros.
              </Li>
            </Ul>
          </Body>
          <Body>
            Em qualquer caso, o Proprietário ajudará de bom grado a esclarecer a
            base legal específica que se aplica ao processamento e, em
            particular, se o fornecimento de Dados
          </Body>
          <Body>
            Pessoais é uma exigência legal ou contratual, ou um requisito
            necessário para celebrar um contrato.
          </Body>
          <Subtitle>Local</Subtitle>
          <Body>
            Os dados são processados nos escritórios operacionais do
            proprietário e em qualquer outro local onde as partes envolvidas no
            processamento estejam localizadas
          </Body>
          <Body>
            Dependendo da localização do usuário, as transferências de dados
            podem envolver a transferência dos dados do usuário para um país
            diferente do seu. Para saber mais sobre o local de processamento de
            tais dados transferidos, os usuários podem verificar a seção que
            contém detalhes sobre o processamento de dados pessoais.
          </Body>
          <Body>
            Se tal transferência ocorrer, os Usuários poderão obter mais
            informações, verificando as seções relevantes deste documento ou
            consultando o Proprietário, usando as informações fornecidas na
            seção de contato.
          </Body>
          <Subtitle>Tempo de retenção</Subtitle>
          <Body>
            Os Dados Pessoais devem ser processados e armazenados pelo tempo
            requerido pelo propósito para o qual foram coletados.
          </Body>
          <Body>Assim sendo:</Body>
          <Body>
            <Ul>
              <Li>
                Os Dados Pessoais coletados para fins relacionados ao
                cumprimento de um contrato entre o Proprietário e o Usuário
                serão retidos até que tal contrato tenha sido totalmente
              </Li>
              <Li>
                Os Dados Pessoais coletados para os propósitos dos interesses
                legítimos do Proprietário serão retidos pelo tempo necessário
                para cumprir tais propósitos. Os usuários podem encontrar
                informações específicas sobre os interesses legítimos do
                Proprietário nas seções relevantes deste documento ou entrar em
                contato com o proprietário.
              </Li>
            </Ul>
          </Body>
          <Body>
            O Proprietário pode ser autorizado a reter Dados Pessoais por um
            período mais longo sempre que o Usuário der consentimento para tal
            processamento, desde que tal consentimento não seja retirado. Além
            disso, o proprietário pode ser obrigado a reter dados pessoais por
            um período mais longo sempre que necessário para o desempenho de uma
            obrigação legal ou por ordem de uma autoridade.
          </Body>
          <Body>
            Quando o período de retenção expirar, os Dados Pessoais serão
            excluídos. Portanto, o direito de acesso, o direito de apagar, o
            direito de retificação e o direito à portabilidade de dados não
            podem ser cumpridos após a expiração do período de retenção.
          </Body>
          <Title2>Os propósitos do processamento</Title2>
          <Body>
            Os dados relativos ao usuário são coletados para permitir que o
            proprietário forneça seus serviços, bem como para os seguintes
            propósitos: interação com plataformas de pesquisa on-line,
            gerenciamento de banco de dados do usuário, tratamento de
            pagamentos, análise, gerenciamento de tags, interação com redes
            sociais e plataformas externas, remarketing e segmentação
            comportamental, interação com plataformas de coleta de dados e
            outros terceiros, contato com o usuário, interação com plataformas
            de bate-papo ao vivo, gerenciamento de contatos e envio de
            mensagens, desempenho de conteúdo e infraestrutura monitoramento.
          </Body>
          <Body>
            Os usuários podem encontrar informações detalhadas adicionais sobre
            esses propósitos de processamento e sobre os Dados Pessoais
            específicos usados para cada finalidade nas respectivas seções deste
            documento.
          </Body>
          <Body>
            Os usuários podem exercer certos direitos sobre seus dados
            processados pelo proprietário.
          </Body>
          <Body>
            Em particular, os usuários têm o direito de fazer o seguinte:
          </Body>
          <Body>
            <Ul>
              <Li>
                Retire seu consentimento a qualquer momento. Os usuários têm o
                direito de retirar o consentimento, desde que tenham dado seu
                consentimento para o processamento de seus dados pessoais.
              </Li>
              <Li>
                Opor-se ao processamento de seus dados. Os usuários têm o
                direito de se opor ao processamento de seus dados se o
                processamento for realizado em uma base legal que não seja o
                consentimento. Mais detalhes são fornecidos na seção dedicada
                abaixo.
              </Li>
              <Li>
                Verificar e buscar retificação. Os usuários têm o direito de
                verificar a exatidão de seus dados e solicitar que sejam
                atualizados ou corrigidos.
              </Li>
              <Li>
                Restringir o processamento de seus dados. Os usuários têm o
                direito, sob determinadas circunstâncias, de restringir o
                processamento de seus dados. Nesse caso, o proprietário não
                processará seus dados para qualquer finalidade que não seja
                armazená-los.
              </Li>
              <Li>
                Ter seus dados pessoais excluídos ou removidos. Os usuários têm
                o direito, sob determinadas circunstâncias, de obter o
                apagamento de seus dados do proprietário.
              </Li>
              <Li>
                Apresentar uma queixa. Os usuários têm o direito de apresentar
                uma reclamação perante sua autoridade competente de proteção de
                dados.
              </Li>
            </Ul>
          </Body>
          <Body>
            Os usuários devem saber que, no entanto, caso seus dados pessoais
            sejam processados para fins de marketing direto, eles podem se opor
            a esse processamento a qualquer momento sem fornecer qualquer
            justificativa. Para saber se o proprietário está processando dados
            pessoais para fins de marketing direto, os usuários podem consultar
            as seções relevantes deste documento.
          </Body>
          <Subtitle>Como exercer esses direitos</Subtitle>
          <Body>
            Quaisquer solicitações para exercer direitos de usuário podem ser
            direcionadas ao proprietário através dos detalhes de contato
            fornecidos neste documento. Estes pedidos podem ser exercidos
            gratuitamente e serão endereçados pelo Proprietário o mais cedo
            possível e sempre dentro de um mês.
          </Body>
          <Subtitle>
            Informações adicionais sobre os dados pessoais do usuário
          </Subtitle>
          <Body>
            {`Além das informações contidas nesta política de privacidade, o `}
            <BlackText>MEUCATALOGO</BlackText>
            {` pode fornecer ao usuário informações adicionais e contextuais sobre determinados serviços ou a coleta e processamento de dados pessoais mediante solicitação.`}
          </Body>
          <Subtitle>Logs e manutenção do sistema</Subtitle>
          <Body>
            {`Para fins de operação e manutenção, o `}
            <BlackText>MEUCATALOGO</BlackText>
            {` e quaisquer serviços de terceiros podem coletar arquivos que registram a interação com o `}
            <BlackText>MEUCATALOGO</BlackText>
            {` (registros do sistema) usando outros dados pessoais (como o endereço IP) para essa finalidade.`}
          </Body>
          <Subtitle>Informações não contidas nesta política</Subtitle>
          <Body>
            Mais detalhes sobre a coleta ou processamento de dados pessoais
            podem ser solicitados ao Proprietário a qualquer momento.
          </Body>
          <Subtitle>Alterações a esta política de privacidade</Subtitle>
          <Body>
            O proprietário se reserva o direito de fazer alterações a esta
            política de privacidade a qualquer momento, notificando seus
            usuários nesta página e possivelmente dentro do próprio usuário e/ou
            – na medida do possível técnica e legalmente – enviando um aviso aos
            usuários através de qualquer informação de contato disponível para o
            proprietário. É altamente recomendável verificar essa página com
            frequência, consultando a data da última modificação listada na
            parte inferior.
          </Body>
          <Body>
            Se as alterações afetarem as atividades de processamento realizadas
            com base no consentimento do usuário, o proprietário deverá coletar
            novo consentimento do usuário, quando necessário.
          </Body>
          <Body style={{ marginTop: '80px' }}>
            {`Esta política de privacidade refere-se unicamente ao `}
            <BlackText>MEUCATALOGO</BlackText>
            {` , se não indicado de outra forma neste documento.`}
          </Body>
        </Content>
      </PoliticasContent>
      <DivideFooter />
      <Footer classe="footer-termos" />
    </>
  );
};

export default Politicas;
