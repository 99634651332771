import React from 'react';

import { HashLink as Link } from 'react-router-hash-link';

import {
  BtnPanelLogin,
  ClassHeader,
  IconLogin,
  Links,
  Login,
  Logo,
  LogoCatalogo,
  Menu,
  MenuItem,
} from './styles';

interface IHeaderProps {
  classe: string;
}

const Header: React.FC<IHeaderProps> = ({ classe }) => {
  return (
    <ClassHeader minhaClasse={classe}>
      <Login>
        <Links
          href={process.env.REACT_APP_URL_WEB}
          rel="noopener noreferrer"
          target="_blank"
        >
          <IconLogin src="Imagens/log-in-outline.svg" />
        </Links>
      </Login>

      <Logo>
        <Link to="/#home">
          <LogoCatalogo
            md
            src="https://cdn-rac.azureedge.net/assets/site-catalogo/logo-originalHorizontal.png"
          />
        </Link>

        <Link to="/#home">
          <LogoCatalogo src="https://cdn-rac.azureedge.net/assets/site-catalogo/Ícone.png" />
        </Link>
      </Logo>

      <Menu>
        <Link to="/#home">
          <MenuItem>Home</MenuItem>
        </Link>

        <Link to="/#sobre">
          <MenuItem>Sobre</MenuItem>
        </Link>

        <Link to="/#recursos">
          <MenuItem>Recursos</MenuItem>
        </Link>

        <Link to="/#planos">
          <MenuItem>Planos</MenuItem>
        </Link>

        <Link to="/#contato">
          <MenuItem>Contato</MenuItem>
        </Link>

        <Links
          href={process.env.REACT_APP_URL_WEB}
          rel="noopener noreferrer"
          target="_blank"
        >
          <BtnPanelLogin>Entrar</BtnPanelLogin>
        </Links>
      </Menu>
    </ClassHeader>
  );
};

export default Header;
