import React from 'react';

interface IDisplayComponent {
  when: boolean;
}

const DisplayComponent: React.FC<IDisplayComponent> = ({ children, when }) => {
  return <>{when && children}</>;
};

export default DisplayComponent;
