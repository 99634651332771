import React from 'react';
import './style.css';

import {
  About,
  ContentAbout,
  ImgAbout,
  Right,
  Subtitle,
  Title,
} from './styles';

interface ISobreProps {
  conteudo: string;
  titulo: string;
}

const Sobre: React.FC<ISobreProps> = ({ conteudo, titulo }) => {
  return (
    <About id="sobre">
      <ContentAbout md>
        <ImgAbout src="https://cdn-rac.azureedge.net/assets/site-catalogo/img-sobre.png" />

        <Right>
          <Title>{titulo}</Title>
          <Subtitle>{conteudo}</Subtitle>
        </Right>
      </ContentAbout>
      <ContentAbout>
        <Right>
          <Title>{titulo}</Title>
          <Subtitle>{conteudo}</Subtitle>
        </Right>
        <ImgAbout src="https://cdn-rac.azureedge.net/assets/site-catalogo/img-sobre.png" />
      </ContentAbout>
    </About>
  );
};

export default Sobre;
