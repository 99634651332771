import styled from 'styled-components';

export const PoliticasContent = styled.div`
  display: grid;
  justify-items: center;
  padding-bottom: 3rem;
  padding-top: 8rem;
`;

export const Title2 = styled.h2`
  color: #22536c;
  font-size: 1.8rem;
  font-weight: 400;
  margin-top: 50px;
`;

export const Title = styled.h1`
  color: #37aa9c;
  font-size: 2.5rem;
  font-weight: 400;
  text-align: center;
`;

export const Subtitle = styled.p`
  color: #37aa9c;
  font-size: 1.5rem;
  font-weight: 300;
  margin: 10px;
`;

export const Body = styled.div`
  color: #000;
  font-size: 1.125rem;
  font-weight: 300;
  margin: 10px;
  text-align: justify;
`;

export const Content = styled.div`
  width: 80%;
`;

export const DivideFooter = styled.hr`
  background-image: linear-gradient(to right, transparent, #ccc, transparent);
  border: 0;
  height: 2px;
`;

export const Ul = styled.ul`
  padding: 15px;
  &::before {
    display: inline-block;
    font-weight: bold;
    margin-left: -1em;
    width: 1em;
  }
`;

export const Li = styled.li`
  &::before {
    display: inline-block;
    font-weight: bold;
    margin-left: -1em;
    width: 1em;
  }
`;

export const BlackText = styled.span`
  font-weight: 500;
`;
