import { useContext } from 'react';

import { ToastContext } from './provider';

export interface IToastMessage {
  id: string;
  title: string;
  type?: 'success' | 'error' | 'info';
}

export interface IUseToastContext {
  addToast(message: Omit<IToastMessage, 'id'>): void;
  removeToast(id: string): void;
}

function useToast(): IUseToastContext {
  const toastContext = useContext(ToastContext);

  return toastContext;
}

export default useToast;
