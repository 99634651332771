import styled, { css } from 'styled-components';

interface IScreen {
  md?: boolean;
  padding?: boolean;
  sm?: boolean;
}

export const DivBanner = styled.div`
  background-position-x: right;
  background-repeat: no-repeat;
  @media only screen and (max-width: 450px) {
    background-image: url('https://cdn-rac.azureedge.net/assets/site-catalogo/m-bkg-banner.png');
    background-position-y: bottom;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    background-image: url('https://cdn-rac.azureedge.net/assets/site-catalogo/m-bacground-2.png');
    background-position-y: bottom;
  }
  @media only screen and (min-width: 1361px) {
    background-image: url('https://cdn-rac.azureedge.net/assets/site-catalogo/bkg-banner.png');
    height: 100vh;
  }
  @media only screen and (min-width: 1024px) {
    background-image: url('https://cdn-rac.azureedge.net/assets/site-catalogo/teste.png');
    height: 100vh;
  }
`;

export const Content = styled.div`
  align-items: center;
  height: 100%;
  @media only screen and (max-width: 1023px) {
    display: grid;
    justify-items: center;
    padding-bottom: 40px;
  }
  @media only screen and (min-width: 1024px) {
    display: flex;
    justify-content: space-evenly;
    padding: 30px;
  }
`;

export const ImgSreen = styled.img<IScreen>`
  width: 200px;
  @media only screen and (max-width: 1023px) {
    padding-top: 30px;
  }
  @media only screen and (min-width: 1024px) {
    display: none;
  }

  ${props =>
    props.md &&
    css`
      @media only screen and (max-width: 1023px) {
        display: none;
      }
      @media only screen and (min-width: 1024px) {
        display: block;
      }
    `};
`;

export const Left = styled.div`
  @media only screen and (max-width: 450px) {
    text-align: center;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    text-align: center;
    width: 60%;
  }
  }
  @media only screen and (min-width: 1361px) {
    width: 60%;
  }
  @media only screen and (min-width: 1024px) {
    width: 60%;
  }
`;

export const Title = styled.p`
  color: #1a6d5f;
  margin-bottom: 0;
  margin-top: 0;
  @media only screen and (max-width: 1023px) {
    font-size: 25px;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 50px;
  }
`;

export const Subtitle = styled.p<IScreen>`
  color: #354649;
  font-weight: 300;
  margin-bottom: 0;
  margin-top: 0;

  @media only screen and (max-width: 1023px) {
    font-size: 17px;
    text-align: center;
    width: 85%;
  }

  @media only screen and (min-width: 1024px) {
    display: none;
  }

  ${props =>
    props.md &&
    css`
      @media only screen and (max-width: 1023px) {
        font-size: 17px;
        text-align: center;
        width: 85%;
        display: none;
      }
      @media only screen and (min-width: 1024px) {
        font-size: 22px;
        display: block;
      }
    `};

  ${props =>
    props.padding &&
    css`
      padding-top: 15px;
    `};
`;

export const Buttons = styled.div<IScreen>`
  display: flex;
  margin-right: 10px;
  padding-top: 30px;
  @media only screen and (min-width: 1024px) {
    display: none;
  }
  ${props =>
    props.md &&
    css`
      display: none;
      @media only screen and (max-width: 450px) {
        font-size: 12px !important;
        display: none;
      }
      @media only screen and (max-width: 1023px) and (min-width: 451px) {
        display: none;
      }
      @media only screen and (min-width: 1024px) {
        display: flex;
      }
    `};
`;

export const DonwloadButton = styled.button`
  align-items: center;
  background-color: #354649;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: 'Poppins', sans-serif !important;
  font-size: 1.1em;
  font-weight: 300;
  justify-content: center;
  margin-right: 10px;
  outline: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.4s;
`;

export const ImgButton = styled.img`
  margin-right: 16px;
  width: 30px;
`;

export const TextButtons = styled.div`
  display: grid;
`;

export const Links = styled.a`
  &a:link {
    color: #354649;
    text-decoration: none !important;
  }

  &a:visited {
    color: #354649 !important;
    text-decoration: none !important;
  }

  &a:hover {
    color: #354649 !important;
    text-decoration: none !important;
  }

  &a:active {
    color: #354649 !important;
    text-decoration: none !important;
  }
`;

export const SpanButtons = styled.span`
  font-size: 16px;
`;

export const BlackText = styled.span`
  font-weight: 500;
`;
