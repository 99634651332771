import { useContext } from 'react';

import EPlans from 'constants/plans';

import { PaymentContext } from './provider';

interface ICreatePaymentDTOCard {
  cvv: string;
  expiration: Date;
  holder: string;
  number: string;
}

export interface ICreatePaymentDTO {
  data: {
    card?: ICreatePaymentDTOCard;
    companyName?: string;
    document: string;
    email: string;
    name: string;
    password: string;
    phone: string;
    plan: EPlans;
    planId: number;
  };
  functions: {
    openModalSuccess(): void;
    resetForm(): void;
    stopLoading(): void;
  };
}

export interface IUsePaymentContext {
  createPayment(data: ICreatePaymentDTO): Promise<void>;
}

function usePayment(): IUsePaymentContext {
  const paymentContext = useContext(PaymentContext);

  return paymentContext;
}

export default usePayment;
