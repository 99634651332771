const colors = {
  white: '#FFF',
  lighter: '#EEE',
  light: '#DDD',
  regular: '#999',
  dark: '#666',
  darker: '#2d2d2d',
  black: '#272525',

  darkTransparent: '#0000005e',

  secondary: '#354649',

  secondaryText: '#6C7A89',

  primary: '#37AA9C',

  error: '#aa3737',
  success: '#309630',
  danger: '#f7ae1b',

  borderColor: '#DEDEDE',
};

export default colors;
