import styled, { css } from 'styled-components';

import { animated } from 'react-spring';

import colors from 'styles/colors';

interface IToastContentProps {
  type?: 'success' | 'error' | 'info';
}

const toastTypeVariations = {
  info: css`
    background: ${colors.danger};
    color: #fff;
  `,
  success: css`
    background: ${colors.success};
    color: #fff;
  `,
  error: css`
    background: ${colors.error};
    color: #fff;
  `,
};

export const ToastContent = styled(animated.div)<IToastContentProps>`
  border-radius: 1rem;
  box-shadow: 0.2rem 0.2rem 0.8rem rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  margin-top: 0.8rem;
  max-width: 36rem;
  padding: 1.6rem;
  position: relative;

  ${props => toastTypeVariations[props.type || 'info']};
`;

export const ToastDescription = styled.div`
  flex: 1;
  margin-left: 0.8rem;
  position: relative;
`;

export const ToastTitle = styled.strong`
  font-weight: 500;
`;

export const ToastClose = styled.button`
  background: transparent;
  border: 0;
  color: inherit;
  margin-left: 0.8rem;
  width: 3rem;
`;
