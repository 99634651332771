import React from 'react';

import './style.css';

import { ImgNotFound, PageNotFound } from './styles';

const NotFound: React.FC = () => {
  return (
    <PageNotFound>
      <ImgNotFound src="Imagens/notfound.png" />
    </PageNotFound>
  );
};

export default NotFound;
