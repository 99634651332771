import { parseISO } from 'date-fns';
import { ValidationError } from 'yup';

interface IErrors {
  [key: string]: string;
}

interface IMakeDate {
  month: string;
  year: string;
}

function getValidationErrors(err: ValidationError): IErrors {
  const validationErrors: IErrors = {};

  err.inner.forEach(error => {
    validationErrors[error.path] = error.message;
  });

  return validationErrors;
}

function makeDate({ month, year }: IMakeDate): Date {
  const dateFormatted = `${year}-${month}-01`;

  const dateFormattedParsed = parseISO(dateFormatted);

  return dateFormattedParsed;
}

export default { getValidationErrors, makeDate };
