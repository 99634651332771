import React from 'react';

import UseContactProvider from './useContact/provider';
import UsePaymentProvider from './usePayment/provider';
import UseToastProvider from './useToast/provider';

const AppProvider: React.FC = ({ children }) => {
  return (
    <UseToastProvider>
      <UseContactProvider>
        <UsePaymentProvider>{children}</UsePaymentProvider>
      </UseContactProvider>
    </UseToastProvider>
  );
};

export default AppProvider;
