import React from 'react';

import { TooltipContainer, TooltipTitle } from './styles';

interface ITooltipProps {
  title: string;
}

const Tooltip: React.FC<ITooltipProps> = ({ children, title }) => {
  return (
    <TooltipContainer>
      {children}
      <TooltipTitle>{title}</TooltipTitle>
    </TooltipContainer>
  );
};

export default Tooltip;
