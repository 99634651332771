import styled, { css } from 'styled-components';

interface IScreen {
  md?: boolean;
}

export const About = styled.div<IScreen>`
  background-color: #37aa9c;
  @media only screen and (max-width: 1023px) {
    display: grid;
    justify-items: center;
  }

  @media only screen and (min-width: 1024px) {
    height: 100vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
`;

export const ContentAbout = styled.div<IScreen>`
  display: none;

  ${props =>
    props.md &&
    css`
      @media only screen and (max-width: 1023px) {
        display: grid;
        justify-items: center;
      }
      @media only screen and (min-width: 1024px) {
        align-items: center;
        display: flex;
        justify-content: space-evenly;
      }
    `}

  @media only screen and (min-width: 1024px) {
    ${props =>
      props.md &&
      css`
        align-items: center;
        display: flex;
        justify-content: space-evenly;
      `}
  }
`;

export const Right = styled.div`
  @media only screen and (max-width: 450px) {
    width: 85%;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    width: 60%;
  }
  @media only screen and (min-width: 1024px) {
    width: 45%;
  }
`;

export const ImgAbout = styled.img`
  @media only screen and (max-width: 450px) {
    width: 120px;
  }
  @media only screen and (min-width: 451px) {
    width: 250px;
  }
`;

export const Title = styled.p`
  color: #e3d985;
  margin-bottom: 0;
  @media only screen and (max-width: 450px) {
    font-size: 35px;
    text-align: center;
    padding-top: 40px;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    font-size: 35px;
    text-align: center;
    padding-bottom: 40px;
    padding-top: 40px;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 50px;
  }
`;

export const Subtitle = styled.p`
  color: #fff;
  @media only screen and (max-width: 450px) {
    font-size: 16px;
    text-align: center;
    padding-bottom: 30px;
    line-height: normal;
    line-height: normal;
  }
  @media only screen and (max-width: 1023px) and (min-width: 451px) {
    font-size: 15px;
    text-align: justify;
    padding-bottom: 80px;
  }
  @media only screen and (min-width: 1024px) {
    font-size: 18px;
    text-align: justify;
    line-height: normal;
  }
`;
