import amexImg from '../assets/cardBrands/amex.png';
import eloImg from '../assets/cardBrands/elo.png';
import hipercardImg from '../assets/cardBrands/hipercard.png';
import mastercardImg from '../assets/cardBrands/mastercard.png';
import defaultImg from '../assets/cardBrands/meucatalogo-card.png';
import visaImg from '../assets/cardBrands/visa.png';

interface ICardBrand {
  [key: string]: string;
}

const cardBrands: ICardBrand = {
  default: defaultImg,
  'american-express': amexImg,
  elo: eloImg,
  hipercard: hipercardImg,
  mastercard: mastercardImg,
  visa: visaImg,
};

export default cardBrands;
